import React from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';

import AuthUserContext from './authUserContext';
import { withFirebase } from '../Firebase';
import {ROLES} from '../../constants';
import withAuthentication from "./withAuthentication";

const withAuthorization = condition => Component => {
    class WithAuthorization extends React.Component {
        componentDidMount() {
            this.listener = this.props.firebase.auth.onAuthStateChanged(
                authUser => {
                    if (authUser) {
                        this.props.firebase.user(authUser.uid).get().then(snapshot => {
                            authUser.role = snapshot.data().role;
                            authUser.crewPosition = snapshot.data().crewPosition;
                            if (authUser.role === ROLES.UNVALIDATED) {
                                this.props.history.push("/need-validation");
                            }
                            else if (!condition(authUser)) {
                                this.props.history.push("/signin");
                            }
                        });
                    } else {
                        this.props.history.push("/signin");
                    }
                },
            );
        }

        componentWillUnmount() {
            this.listener();
        }

        render() {
            return (
                <AuthUserContext.Consumer>
                  {authUser =>
                    condition(authUser) ? <Component authUser={ authUser } {...this.props} /> : null
                  }
                </AuthUserContext.Consumer>
            );
        }
    }

    return compose(
        withRouter,
        withFirebase,
        withAuthentication,
    )(WithAuthorization);
};

export default withAuthorization;