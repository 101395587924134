import React, {useEffect, useState} from 'react';

import {ROLES, CREW_QUAL, CREW_POS, isAdmin} from "../../constants";
import {withAuthorization} from "../Session";
import {
    Hidden,
    IconButton,
    Paper,
    Grid,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    withStyles,
} from "@material-ui/core";
import {compose} from "recompose";
import {appStyles} from "../../styles";
import {withFirebase} from "../Firebase";
import Dropdown from "../Forms/Dropdown";
import {Check, Clear} from '@material-ui/icons';
import {AlertDialog, HoverControls} from "../Forms";

const AdminPage = (props) => {
    const {classes, firebase} = props;
    const [loading, setLoading] = useState(false);
    const [users, setUsers] = useState([]);

    useEffect(() => {
        setLoading(true);
        return firebase.users().onSnapshot(snapshot => {
                let users = [];
                snapshot.forEach(doc => (users.push({id: doc.id, ...doc.data()})));
                setUsers(users);
                setLoading(false);
            });
    }, [firebase]);

    return (
        <Paper className={classes.paper}>
            <h1 className={classes.userContainer}>Users</h1>

            {loading && <div>Loading ...</div>}

            <UserTable users={users} />
        </Paper>
    );
};

const UserTable = withStyles(appStyles)((props) => {
    const {users, classes} = props;
    const [editing, setEditing] = useState(false);

    return (
        <Table className={classes.table}>
            <TableHead>
                <TableRow>
                    <TableCell>
                    <Grid container>
                        <Grid item xs={5} sm={3}>Name</Grid>
                        <Grid item xs={7} sm={5}>Email</Grid>
                        <Grid item xs={5} sm={2}>Role</Grid>
                        <Grid item xs={5} sm={1}>Qual</Grid>
                        <Grid item xs={2} sm={1} align={"right"}>Crew</Grid>
                    </Grid>
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {users.map((user) => (
                    <UserRow user={user} lockEditing={setEditing} isEditingLocked={editing}/>
                ))}
            </TableBody>
        </Table>
    )
});

const UserRow = compose(withFirebase, withStyles(appStyles))((props) => {
    const {classes, firebase, user, lockEditing, isEditingLocked} = props;
    const [editing, setEditing] = useState(false);
    const [role, setRole] = useState(user.role);
    const [qual, setQual] = useState(user.qual);
    const [crewPosition, setCrewPosition] = useState(user.crewPosition);
    const [confirmDelete, setConfirmDelete] = useState(false);

    const reset = () => {
        setEditing(false);
        lockEditing(false);
    };

    const edit = () => {
        lockEditing(true);
        setEditing(true);
        setRole(user.role);
        setQual(user.qual);
        setCrewPosition(user.crewPosition);
    };

    // this doesn't feel like the right component level to perform database actions at,
    // but if it's done at the AdminPage level notifying children of the Dropdown's clean state would be even worse...
    const save = () => {
        firebase.user(user.id).update({
            role: role,
            qual: qual,
            crewPosition: crewPosition,
        }).then(() => {
            reset();
        }).catch(error => {
            //TODO
            console.log(error);
        });
    };

    /*
     * This feature will not be implemented until the delete button will successfully remove the user from
     * firebase authentication and clean up all database references to the user.
     *
    const deleteUser = () => {
        setConfirmDelete(false);
        //TODO: remove user from flights
        //TODO: remove account from firebase authentication
        props.firebase.user(user.id).remove().then(() => {
                props.history.push(ROUTES.ADMIN.path);
            }).catch(error => {
                //TODO
                console.log(error);
            });
    };
    */

    return (
        <>
            <TableRow key={user.id} className={classes.hoverRow}>
                <Hidden xsUp={editing}>
                    <TableCell>
                    <Grid container>
                        <Grid item xs={5} sm={3}>{user.username}</Grid>
                        <Grid item xs={7} sm={5}>{user.email}</Grid>
                        <Grid item xs={5} sm={2}>{
                            Object.keys(ROLES).map((key, value) => {
                                return user.role === value ? key : "";
                            })}
                        </Grid>
                        <Grid item xs={5} sm={1}>{CREW_QUAL[user.qual]}</Grid>
                        <Grid item xs={2} sm={1} className={classes.hoverCell}>
                            <HoverControls
                                content={CREW_POS[user.crewPosition]}
                                hidden={isEditingLocked}
                                onEdit={edit}
                                onDelete={() => setConfirmDelete(true)}
                            />
                        </Grid>
                    </Grid>
                    </TableCell>
                </Hidden>
                <Hidden xsUp={!editing}>
                    <TableCell>
                        <Grid container>
                            <Grid item xs={5} sm={3}>
                                {user.username}
                            </Grid>
                            <Grid item xs={7} sm={5}>
                                {user.email}
                            </Grid>
                            <Grid item xs={5} sm={2}>
                                <Dropdown label='Role'
                                          value={role}
                                          onChange={(value) => {setRole(parseInt(value))}}
                                          options={Object.keys(ROLES)}
                                />
                            </Grid>
                            <Grid item xs={5} sm={1}>
                                <Dropdown label='Qual'
                                          value={qual}
                                          onChange={setQual}
                                          options={CREW_QUAL}
                                />
                            </Grid>
                            <Grid item xs={2} sm={1}>
                                <Dropdown label='Crew'
                                          value={crewPosition}
                                          onChange={setCrewPosition}
                                          options={CREW_POS}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <IconButton className={[classes.alignRight]} onClick={save} color={"primary"}>
                                    <Check/>
                                </IconButton>
                                <IconButton className={[classes.alignRight]} onClick={reset} color={"secondary"}>
                                    <Clear/>
                                </IconButton>
                            </Grid>
                        </Grid>
                    </TableCell>
                </Hidden>
            </TableRow>
            <AlertDialog
                open={confirmDelete}
                title={"Sorry"}
                text={"Unfortunately deleting users is not handled at this time. Please contact a developer to help you"}
                onClose={() => setConfirmDelete(false)}
            />
            {/*<ConfirmDialog
                open={confirmDelete}
                title={"Confirm"}
                text={`Are you sure you want to delete ${user.name}?`}
                onCancel={() => setConfirmDelete(false)}
                onConfirm={deleteUser}
            />*/}
        </>
    );
});

export default compose(
    withStyles(appStyles),
    withAuthorization(isAdmin),
)(AdminPage);
