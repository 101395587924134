import React, {useState} from "react";
import {
    Button,
    ButtonGroup,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    List,
    ListItem,
} from "@material-ui/core";

const filters = {
    ALL: "all",
    PILOTS: "P",
    CSOS: "N",
    LMS: "L",
}

const AddCrewMember = (props) => {
    const {members, open, onCancel, onConfirm} = props;
    const [filter, setFilter] = useState(filters.ALL)
    const [selected, setSelected] = useState(undefined);
    return (
        <>
            <Dialog
                open={open}
                onClose={() => {setSelected(undefined); onCancel();}}
            >
                <DialogTitle>Select Member</DialogTitle>
                <DialogContent>
                    <ButtonGroup color={"primary"} disableElevation={true}>
                        <Button
                            variant={filter === filters.ALL ? "contained" : ""}
                            onClick={() => setFilter(filters.ALL)}
                        >
                            All
                        </Button>
                        <Button
                            variant={filter === filters.PILOTS ? "contained" : ""}
                            onClick={() => setFilter(filters.PILOTS)}
                        >
                            Pilots
                        </Button>
                        <Button
                            variant={filter === filters.CSOS ? "contained" : ""}
                            onClick={() => setFilter(filters.CSOS)}
                        >
                            CSOs
                        </Button>
                        <Button
                            variant={filter === filters.LMS ? "contained" : ""}
                            onClick={() => setFilter(filters.LMS)}
                        >
                            LMs
                        </Button>
                    </ButtonGroup>
                    <DialogContentText>
                        <List>
                            {members.map(member => {
                                if (filter === filters.ALL || filter === member.crewPosition) {
                                    return (
                                        <ListItem
                                            button
                                            selected={selected === member.uid}
                                            onClick={() => setSelected(member.uid)}
                                        >
                                            {member.username}
                                        </ListItem>
                                    );
                                } else {
                                    return "";
                                }
                            })}
                        </List>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onCancel} color="secondary">
                        Cancel
                    </Button>
                    <Button
                        disabled={selected === undefined}
                        onClick={() => onConfirm(selected)}
                        color="primary"
                        autoFocus
                    >
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default AddCrewMember;
