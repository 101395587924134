import React from "react";
import {Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button} from "@material-ui/core";

const AlertDialog = (props) => {
    const {open, title, text, onClose} = props;

    return (
      <>
          <Dialog
              open={open}
              onClose={onClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
          >
              <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
              <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                      {text}
                  </DialogContentText>
              </DialogContent>
              <DialogActions>
                  <Button onClick={onClose} color="primary" autoFocus>
                      OK
                  </Button>
              </DialogActions>
          </Dialog>
      </>
    );
}

export default AlertDialog;