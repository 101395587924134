import React, {useState} from "react";
import {Fab, Hidden, Button, withStyles} from "@material-ui/core";
import {Close, Delete, Done, Edit} from "@material-ui/icons";
import {appStyles} from "../../styles";

const FabControls = (props) => {
    const {classes, edit, setEdit, onCancel, onCommit, onDelete, hidden} = props;
    const [localEdit, setLocalEdit] = useState(edit);
    const doCancel = onCancel === undefined ? () => {} : onCancel;
    const doCommit = onCommit === undefined ? () => {} : onCommit;
    const doEdit = setEdit === undefined ? setLocalEdit : (value) => {setLocalEdit(value); setEdit(value);};
    return(
        <>
            <Hidden xsUp={localEdit || hidden}>
                <Fab className={`${classes.fab} ${classes.fabEdit}`}
                     color="primary"
                     aria-label="edit"
                     size={"small"}
                     onClick={() => doEdit(true)}
                >
                    <Edit />
                </Fab>
            </Hidden>
            <Hidden xsUp={!localEdit || hidden}>
                <Fab className={`${classes.fab} ${classes.fabAdd}`}
                     color="secondary"
                     aria-label="edit"
                     size={"small"}
                     onClick={() => {doCommit(); doEdit(false)}}
                >
                    <Done />
                </Fab>
                <Fab className={`${classes.fab}`}
                     color="default"
                     aria-label="edit"
                     size={"small"}
                     onClick={() => {doCancel(); doEdit(false)}}
                >
                    <Close />
                </Fab>
                <Hidden xsUp={onDelete === undefined}>
                    <Fab className={`${classes.fab} ${classes.fabDelete}`}
                         color="secondary"
                         aria-label="edit"
                         size={"small"}
                         onClick={() => {onDelete(); doEdit(false)}}
                    >
                        <Delete />
                    </Fab>
                </Hidden>
            </Hidden>
            <Hidden xsUp={!hidden}>
                <Button disabled={true}/>
            </Hidden>
        </>
    );
}

export default withStyles(appStyles)(FabControls);