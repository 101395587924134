import {
    Button,
    Checkbox,
    Chip,
    Container,
    Fab,
    Hidden,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    withStyles,
} from "@material-ui/core";
import {Add, CheckBoxOutlineBlank, CheckBox, PersonAdd, Remove} from "@material-ui/icons";
import {ConfirmDialog} from "../Forms";
import AddCrewMember from "./addCrewMember";
import React, {useState, useEffect} from "react";
import {appStyles} from "../../styles";
import {withAuthorization} from "../Session";
import {compose} from "recompose";
import {isMember, ROLES} from "../../constants";
import fb from "firebase";
import {AuditableChange} from "../Audit";

const CrewMembers = (props) => {
    const {authUser, classes, firebase, isFlight, eventHandle, userLock, schedLock, adminLock} = props;

    const [confirmJoin, setConfirmJoin] = useState(false);
    const [memberToRemove, setMemberToRemove] = useState({name: ""});
    const [addCrewSelector, setAddCrewSelector] = useState(false);
    const [members, setMembers] = useState([]);
    const [event, setEvent] = useState({crew: {},});

    const canEdit = !adminLock && (!schedLock || authUser.role === ROLES.ADMIN) && authUser.role > ROLES.MEMBER;

    /* The checkbox is used to indicate a checkride for flights, and if the crew member is confirmed
     * for trips. */
    const checkBoxHidden = !isFlight && !canEdit;

    /* Logic to determine if a user can remove themselves. This is based on if the user is currently on the
     * flight/trip. For trips, there is an additional consideration that the user cannot remove themselves
     * after they have been confirmed on that trip.
     */
    const selfOnFlight = () => {
        return event.crew && Object.keys(event.crew).includes(authUser.uid);
    }

    const canRemoveSelf = () => {
        if (selfOnFlight()) {
            let self = event.crew[authUser.uid];
            return !(userLock || self.confirmed);
        }
        return false;
    }

    const crewPositions = [
        {name: "Pilots", data: "P"},
        {name: "CSOs", data: "N"},
        {name: "Loads", data: "L"},
        ]

    useEffect(() => {
        return eventHandle.onSnapshot(doc => {
            if (doc.exists) {
                setEvent(doc.data());
            }
        })
    }, [eventHandle])

    const fillCrewSelector = () => {
        firebase.users().onSnapshot(snapshot => {
            let usersList = [];
            snapshot.forEach(doc => (usersList.push({
                ...doc.data(),
                uid: doc.id,
            })));
            setMembers(usersList);
            setAddCrewSelector(true);
        });
    }

    const addMemberToEvent = (crewId) => {
        if (crewId === undefined) {
            crewId = authUser.uid;
        }
        firebase.user(crewId).get('value').then((snapshot) => {
            if (snapshot.exists) {
                let user = snapshot.data();
                // user data copied by field instead of auto expanded so that new user data
                // isn't automatically replicated to the event
                const delta = {
                    crew:{
                        [`${crewId}`]: {
                            "username": user.username,
                            "email": user.email,
                            "crewPosition": user.crewPosition,
                            "qual": user.qual,
                        }
                    }
                }
                AuditableChange(authUser.uid, eventHandle, event, delta,
                    `Added ${user.username} to the crew`)
            }
        })
    }

    const setMemberCheckride = (crewId, check) => {
        const delta = {
            crew: {
                [`${crewId}`]: {
                    "check": !check,
                }
            }
        }
        AuditableChange(authUser.uid, eventHandle, event, delta,
            `Changed the checkride status`)
    }

    const setMemberConfirmed = (crewId, confirmed) => {
        const delta = {
            crew: {
                [`${crewId}`]: {
                    "confirmed": !confirmed,
                }
            }
        }
        AuditableChange(authUser.uid, eventHandle, event, delta,
            `Changed the confirmed status`)
    }

    const removeMemberFromEvent = (crewId) => {
        if (crewId.id === undefined) {
            crewId.id = authUser.uid;
        }
        const delta = {
            crew: {
                [`${crewId.id}`]: fb.firestore.FieldValue.delete()
            },
        }
        firebase.user(crewId.id).get('value').then((snapshot) => {
            if (snapshot.exists) {
                let user = snapshot.data();
                AuditableChange(authUser.uid, eventHandle, event, delta,
                    `Removed ${user.username} from the crew`)
            } else {
                AuditableChange(authUser.uid, eventHandle, event, delta,
                    `Removed an unknown person from the crew`)
            }
        })
    }

    return (
        <Paper className={classes.flightPaper}>
            {selfOnFlight() ?
                <>
                    <Hidden xsUp={!canRemoveSelf()}>
                        <Fab className={`${classes.fab} ${classes.fabDelete}`}
                             color="secondary"
                             aria-label="remove"
                             size={"small"}
                             onClick={() => setMemberToRemove({id: authUser.uid, name: "yourself"})}
                        >
                            <Remove />
                        </Fab>
                    </Hidden>
                    <Hidden xsUp={!userLock}>
                        <Button disabled={true}/>
                    </Hidden>
                </>
                :
                <>
                    <Hidden xsUp={userLock}>
                        <Fab className={`${classes.fab} ${classes.fabAdd}`}
                             color="secondary"
                             aria-label="add"
                             size={"small"}
                             onClick={() => setConfirmJoin(true)}
                        >
                            <Add />
                        </Fab>
                    </Hidden>
                    <Hidden xsUp={!userLock}>
                        <Button disabled={true}/>
                    </Hidden>
                </>
            }
            <ConfirmDialog
                title={"Confirm"}
                text={`Are you sure you want to join this ${isFlight ? "flight" : "trip"}?`}
                open={confirmJoin}
                onCancel={() => setConfirmJoin(false)}
                onConfirm={() => {setConfirmJoin(false); addMemberToEvent();}}
            />
            <ConfirmDialog
                title={"Confirm"}
                text={`Are you sure you want to remove ${memberToRemove.name} from the ${isFlight ? "flight" : "trip"}?`}
                open={memberToRemove.name !== undefined && memberToRemove.name !== ""}
                onCancel={() => setMemberToRemove({name: ""})}
                onConfirm={() => {removeMemberFromEvent(memberToRemove); setMemberToRemove({});}}
            />
            <Hidden xsUp={!canEdit}>
                <Fab className={classes.fab}
                     color="default"
                     aria-label="add-member"
                     size={"small"}
                     onClick={fillCrewSelector}
                >
                    <PersonAdd />
                </Fab>
            </Hidden>
            <AddCrewMember
                members={members}
                open={addCrewSelector}
                onCancel={() => setAddCrewSelector(false)}
                onConfirm={(result) => {addMemberToEvent(result); setAddCrewSelector(false);}}
            />
            <TableContainer component={Container} className={classes.flightContainer}>
                <b>Crew</b>
                <Table className={classes.table} size={"small"}>
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell align={"right"}>Qual</TableCell>
                            <Hidden xsUp={checkBoxHidden}><TableCell align={"right"}>{isFlight ? "Check" : "Confirmed"}</TableCell></Hidden>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            crewPositions.map(cp => {
                                let data = [];
                                if (event.crew) {
                                    Object.entries(event.crew).sort().map(([id, p]) => {
                                        if (cp.data === p.crewPosition) {
                                            data.push({id: id, ...p});
                                        }
                                        return null;
                                    })
                                }
                                return (
                                    <>{
                                        data.length > 0 ? data.map(person => {
                                            let personLocked = !canEdit || person.confirmed
                                            return (
                                                <TableRow>
                                                    <TableCell>
                                                        {(isFlight && !canEdit) ? person.username :
                                                            <Chip
                                                                size={"small"}
                                                                variant={person.confirmed ? "default" : "outlined"}
                                                                label={person.username}
                                                                onDelete={personLocked ? "" : () => setMemberToRemove({
                                                                    id: person.id,
                                                                    name: person.username,
                                                                })}
                                                            />
                                                        }
                                                    </TableCell>
                                                    <TableCell align={"right"}>{person.qual + person.crewPosition}</TableCell>
                                                    <Hidden xsUp={checkBoxHidden}>
                                                        <TableCell align={"right"}>
                                                            <Checkbox
                                                                color={"primary"}
                                                                checked={isFlight ? person.check : person.confirmed}
                                                                disabled={!canEdit}
                                                                icon={<CheckBoxOutlineBlank fontSize="small"/>}
                                                                checkedIcon={<CheckBox fontSize="small"/>}
                                                                onClick={isFlight ? () => setMemberCheckride(person.id, person.check) : () => setMemberConfirmed(person.id, person.confirmed) }
                                                            />
                                                        </TableCell>
                                                    </Hidden>
                                                </TableRow>
                                            )
                                        })
                                        :
                                        <TableRow>
                                            <TableCell colSpan={checkBoxHidden ? 2 : 3}>No {cp.name} Scheduled</TableCell>
                                        </TableRow>
                                    }</>
                                )
                            })
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    )
}

export default compose(
    withStyles(appStyles),
    withAuthorization(isMember)
)(CrewMembers);