import {ConfirmDialog, DateSelector, FabControls} from "../Forms";
import {Grid, Paper, TextField, withStyles} from "@material-ui/core";
import {adjustTime, utcDate} from "../Session/utils";
import React, {useCallback, useEffect, useState} from "react";
import {compose} from "recompose";
import {appStyles} from "../../styles";
import {withAuthorization} from "../Session";
import {isMember} from "../../constants";
import {ROUTES} from "../../App";
import {AuditableChange} from "../Audit";

const TripData = (props) => {
    const {authUser, classes, firebase, tripId, trip, editHidden} = props;
    const [editSortie, setEditSortie] = useState(false);
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [departureTime, setDepartureTime] = useState(new Date());
    const [returnTime, setReturnTime] = useState(new Date());
    const [tripLocation, setTripLocation] = useState("");

    const onCommit = () => {
        const delta = {
            xcd: getFlight(departureTime, trip.xcd),
            xcr: getFlight(returnTime, trip.xcr),
            location: tripLocation,
        }
        AuditableChange(authUser.uid, firebase.trip(tripId), trip, delta,
            "Edited trip data")
    };

    const onCancel = useCallback(() => {
        setEditSortie(false);
        setDepartureTime(utcDate(trip.xcd.takeoffTime));
        setReturnTime(utcDate(trip.xcr.landTime));
        setTripLocation(trip.location);
    }, [trip]);

    const onDelete = () => {
        setConfirmDelete(false);
        AuditableChange(authUser.uid, firebase.trip(tripId), trip, null, null,() => {
            props.history.push(ROUTES.TRIP_BOARD.path)
        })
    };

    useEffect(() => {
        if (!editSortie) {
            onCancel();
        }
    }, [editSortie, trip, onCancel]);

    const getFlight = (flightDate, flight) => {
        let flt = flight;
        flt.eShowTime = adjustTime(flightDate, utcDate(flight.eShowTime));
        flt.oShowTime = adjustTime(flightDate, utcDate(flight.oShowTime));
        flt.crTime = adjustTime(flightDate, utcDate(flight.crTime));
        flt.takeoffTime = adjustTime(flightDate, utcDate(flight.takeoffTime));
        flt.landTime = adjustTime(flightDate, utcDate(flight.landTime, true));
        return flt;
    }

    return (
        <Paper className={classes.flightPaper}>
            <FabControls
                edit={editSortie}
                setEdit={setEditSortie}
                onCancel={onCancel}
                onCommit={onCommit}
                onDelete={() => {setConfirmDelete(true)}}
                hidden={editHidden}
            />
            <ConfirmDialog
                title={"Warning"}
                text={"Are you sure you want to delete this trip? All data connected to this trip will be lost."}
                open={confirmDelete}
                onCancel={() => setConfirmDelete(false)}
                onConfirm={onDelete}
            />
            <Grid container className={classes.flightContainer} justify={"space-evenly"}>
                <Grid item xs={12} sm={6}>
                    <DateSelector
                        label={"Departure Date"}
                        value={departureTime}
                        onChange={(value) => {setDepartureTime(utcDate(adjustTime(value, departureTime)))}}
                        disabled={!editSortie}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <DateSelector
                        label={"Return Date"}
                        value={returnTime}
                        onChange={(value) => {setReturnTime(utcDate(adjustTime(value, returnTime)))}}
                        disabled={!editSortie}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <TextField
                        label={"Location"}
                        value={tripLocation}
                        onChange={(event) => setTripLocation(event.target.value)}
                        disabled={!editSortie}
                    />
                </Grid>
            </Grid>
        </Paper>
    )
};

export default compose(
    withStyles(appStyles),
    withAuthorization(isMember),
)(TripData)