import React, {useEffect, useState} from "react";
import {Link as ReactLink} from "react-router-dom";
import {withAuthorization} from '../Session';
import {
    Grid, Hidden,
    Link,
    Paper,
    LinearProgress,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    withStyles,
} from "@material-ui/core";
import {compose} from "recompose";
import {appStyles} from "../../styles";
import {isMember, showTime} from "../../constants";
import {TripTable} from "../TripBoard";
import {viewDate} from "../Session/utils";

const HomePage = (props) => {
    const {classes, firebase, authUser} = props;
    const [flightsLoading, setFlightsLoading] = useState(false);
    const [flights, setFlights] = useState([]);
    const [tripsLoading, setTripsLoading] = useState(false);
    const [trips, setTrips] = useState([]);

    useEffect(() => {
        setFlightsLoading(true);
        let today = new Date();
        today.setUTCHours(0, 0, 0, 0);

        return firebase.flights()
            .where("takeoffTime", ">", today.toISOString())
            .orderBy("takeoffTime")
            .onSnapshot(snapshot => {
                let flights = [];
                snapshot.forEach(doc => {
                    if (doc.data().crew !== undefined &&
                        doc.data().crew[authUser.uid]) {
                        flights.push({
                            id: doc.id,
                            ...doc.data(),
                        });
                    }
                });
                setFlights(flights);
                setFlightsLoading(false);
            });
    }, [authUser.uid, firebase]);

    useEffect(() => {
        setTripsLoading(true);
        let today = new Date();
        today.setUTCHours(0, 0, 0, 0);

        return firebase.trips()
            .where('xcr.landTime', ">", today.toISOString())
            .orderBy('xcr.landTime')
            .onSnapshot(snapshot => {
                let trips = [];
                snapshot.forEach(doc => {
                    if (doc.data().crew !== undefined &&
                        doc.data().crew[authUser.uid]) {
                        trips.push({
                            id: doc.id,
                            ...doc.data(),
                        });
                    }
                });
                setTrips(trips);
                setTripsLoading(false);
            });
    }, [authUser.uid, firebase]);

    return (
        <>
        <Paper className={classes.paper}>
            <h1 className={classes.userContainer}>My Flights</h1>
            <FlightTable flights={flights} loading={flightsLoading}/>
        </Paper>
        <Paper className={classes.paper}>
            <h1 className={classes.userContainer}>My Trips</h1>
            <TripTable trips={trips} loading={tripsLoading}/>
        </Paper>
        </>
    );
};

const FlightTable = compose(withStyles(appStyles))((props) => {
    const {flights, classes, loading} = props;

    return (
        <Table className={classes.table}>
            <TableHead>
                <TableRow>
                    <TableCell>
                        <Grid container>
                            <Grid item xs={4} sm={2}>Type</Grid>
                            <Grid item xs={5} sm={2}>Date</Grid>
                            <Grid item xs={4} sm={2}>Show</Grid>
                            <Grid item xs={4} sm={2}>TO</Grid>
                            <Grid item xs={4} sm={2}>Land</Grid>
                            <Grid item xs={6} sm={2}>Crew</Grid>
                        </Grid>
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                <Hidden xsUp={!loading}>
                    <TableRow><TableCell><LinearProgress/></TableCell></TableRow>
                </Hidden>
                <Hidden xsUp={loading}>
                    {flights.length > 0 ? flights.map(flight => {
                            return (
                                <>
                                    <FlightRow flight={flight}/>
                                </>
                            );
                        })
                        :
                        <>
                            <Grid container component={TableRow}>
                                <Grid item component={TableCell} xs={12}>None Scheduled</Grid>
                            </Grid>
                        </>
                    }
                </Hidden>
            </TableBody>
        </Table>
    )
});

const FlightRow = withAuthorization(isMember)((props) => {
    const {authUser, flight} = props;
    return (
        <>
            <TableRow key={flight.id}>
                <TableCell>
                    <Grid container>
                        <Grid item xs={4} sm={2}>
                            <Link component={ReactLink} to={`/flight/${flight.id}`}>{flight.sortieType}</Link>
                        </Grid>
                        <Grid item xs={5} sm={2}>{viewDate(new Date(flight.takeoffTime), "dd MMM yy")}</Grid>
                        <Grid item xs={4} sm={2}>{viewDate(new Date(showTime(authUser, flight)), "HHmm")}</Grid>
                        <Grid item xs={4} sm={2}>{viewDate(new Date(flight.takeoffTime), "HHmm")}</Grid>
                        <Grid item xs={4} sm={2}>{viewDate(new Date(flight.landTime), "HHmm")}</Grid>
                        <Grid item xs={6} sm={2}>{Object.entries(flight.crew).map(([id, person]) => {
                            return (
                                <>
                                    {person.username}<br/>
                                </>
                            )
                        })}</Grid>
                    </Grid>
                </TableCell>
            </TableRow>
        </>
    );
});

export default compose(
    withStyles(appStyles),
    withAuthorization(isMember),
)(HomePage);