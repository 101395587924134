import {FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, withStyles} from "@material-ui/core";
import {EVENT_STATUS} from "../../constants";
import React from "react";
import {appStyles} from "../../styles";

const StatusRadioSelector = withStyles(appStyles)((props) => {
    const {hideComplete, allowUnset, value, onChange, name, eventLabel} = props;
    return(
        <>
            <FormControl>
                <FormLabel>{eventLabel}</FormLabel>
                    <RadioGroup
                        row={true}
                        value={value}
                        onChange={onChange}
                        name={name}>
                        {
                            Object.keys(EVENT_STATUS).map((keyName) => {
                                if (!allowUnset && keyName === "UNKNOWN") {
                                    return("");
                                }
                                if (hideComplete && keyName === "COMPLETED") {
                                    return("");
                                }
                                return (
                                    <FormControlLabel
                                        value={keyName}
                                        control={<Radio color={"primary"}/>}
                                        label={EVENT_STATUS[keyName]}
                                    />
                                )
                            })
                        }
                    </RadioGroup>
            </FormControl>
        </>
    )
})

export default StatusRadioSelector;