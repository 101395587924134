/*
This entire file should be moved into firestore collections.
It is the options to fill the flight data.
*/

import {appTheme} from "../../styles";
import amber from "@material-ui/core/colors/amber";
import blue from "@material-ui/core/colors/blue";
import grey from "@material-ui/core/colors/grey";
import green from "@material-ui/core/colors/green";
import purple from "@material-ui/core/colors/purple";
import red from "@material-ui/core/colors/red";

export const DefaultType = "NCSS";
export const DefaultEvent = "LL";
export const DefaultTripLength = 7;
export const DefaultTripStart = 14;

// oShow, eShow, and cr are the negative offsets for those events from takeoff time.
export const SortieDefaults = {
    "NCSS" : {
        name: "Night Tac",
        eShow: 3,
        oShow: 3,
        cr: 2.5,
        takeoffTime: 18.5,
        landTime: 22.5,
        bgColor: blue["700"],
        textColor: appTheme.palette.text.primary,
    },
    "DCSS" : {
        name: "Day Tac",
        eShow: 3,
        oShow: 3,
        cr: 2.5,
        takeoffTime: 13.5,
        landTime: 17.5,
        bgColor: amber["300"],
        textColor: appTheme.palette.text.primary,
    },
    "NPRO" : {
        name: "Night Pro",
        eShow: 2.5,
        oShow: 2.5,
        cr: 2,
        takeoffTime: 18.5,
        landTime: 21.5,
        bgColor: blue["600"],
        textColor: appTheme.palette.text.primary,
    },
    "DPRO" : {
        name: "Day Pro",
        eShow: 2.5,
        oShow: 2.5,
        cr: 2,
        takeoffTime: 13,
        landTime: 16,
        bgColor: amber["200"],
        textColor: appTheme.palette.text.primary,
    },
    "O&B" : {
        name: "O & B",
        eShow: 3,
        oShow: 3,
        cr: 2.5,
        takeoffTime: 8,
        landTime: 16,
        bgColor: grey["400"],
        textColor: appTheme.palette.text.primary,
    },
    "XC" : {
        name: "X Country",
        eShow: 3,
        oShow: 3,
        cr: 2.25,
        takeoffTime: 10,
        landTime: 13,
        bgColor: grey["400"],
        textColor: appTheme.palette.text.primary,
    },
    "Static" : {
        name: "Static Display",
        eShow: 1,
        oShow: 1,
        cr: 1,
        takeoffTime: 10,
        landTime: 13,
        bgColor: red["200"],
        textColor: appTheme.palette.text.primary,
    },
    "Sim" : {
        name: "Simulator",
        eShow: 1,
        oShow: 1,
        cr: 1,
        takeoffTime: 10,
        landTime: 13,
        bgColor: purple["200"],
        textColor: appTheme.palette.text.primary,
    },
    "GT" : {
        name: "Ground Training",
        eShow: 1,
        oShow: 1,
        cr: 1,
        takeoffTime: 10,
        landTime: 13,
        bgColor: green["200"],
        textColor: appTheme.palette.text.primary,
    },
}

export const SortieEvents = {
    "LL" : {
        name: "Low Level",
    },
    "SCA" : {
        name: "SCA",
    },
    "AD" : {
        name: "Air Drop",
    },
    "HAAR" : {
        name: "HAAR",
    },
    "AR" : {
        name: "AR",
    },
    "PYRO" : {
        name: "Rescue Pyro"
    }
}

export const TailNumbers = {
    "TBD" : {name: "TBD",},
    "5864" : {name: "14-5864",},
    "5842" : {name: "15-5842",},
    "5857" : {name: "16-5857",},
    "5858" : {name: "16-5858",},
}