import {compose} from "recompose";
import {Checkbox, Container, FormControlLabel, Grid, Hidden, Paper, withStyles} from "@material-ui/core";
import {appStyles} from "../../styles";
import {withAuthorization} from "../Session";
import {FabControls, TimeSelector} from "../Forms";
import React, {useCallback, useEffect, useState} from "react";
import {subMinutes} from "date-fns";
import {isMember} from "../../constants";
import * as FLIGHT_OPTS from "./flightOptions";
import {CheckBox, CheckBoxOutlineBlank} from "@material-ui/icons";
import {adjustTime, utcDate, zonedDate} from "../Session/utils";
import {AuditableChange} from "../Audit";

const FlightTimes = (props) => {
    const {authUser, classes, fieldName, flightHandle, flight, editHidden, title} = props;
    const [editTimes, setEditTimes] = useState(false);
    const [autoShowTimes, setAutoShowTimes] = useState(true);
    const [oShowTime, setOShowTime] = useState(zonedDate(new Date()));
    const [eShowTime, setEShowTime] = useState(zonedDate(new Date()));
    const [crTime, setCRTime] = useState(zonedDate(new Date()));
    const [takeoffTime, setTakeoffTime] = useState(zonedDate(new Date()));
    const [landTime, setLandTime] = useState(zonedDate(new Date()));

    const onCommit = () => {
        let flightData = flight;
        flightData.autoShowTimes = autoShowTimes;
        flightData.oShowTime = adjustTime(takeoffTime, oShowTime);
        flightData.eShowTime = adjustTime(takeoffTime, eShowTime);
        flightData.crTime = adjustTime(takeoffTime, crTime);
        flightData.takeoffTime = adjustTime(takeoffTime, takeoffTime);
        flightData.landTime = adjustTime(takeoffTime, landTime, true);
        // Puts xcd or xcr data under the right field
        if (fieldName) {
            flightData = {[fieldName]: flightData}
        }
        AuditableChange(authUser.uid, flightHandle, flight, flightData,
            "Changed the flight times")
    }

    const updateDefaults = () => {
        if (!autoShowTimes) {
            changeTakeoffTime(takeoffTime, flight.sortieType, true);
        }
        setAutoShowTimes(!autoShowTimes);
    }

    const changeTakeoffTime = (time, defaultTimes) => {
        if (defaultTimes) {
            setEShowTime(subMinutes(time, FLIGHT_OPTS.SortieDefaults[flight.sortieType].eShow * 60));
            setOShowTime(subMinutes(time, FLIGHT_OPTS.SortieDefaults[flight.sortieType].oShow * 60));
            setCRTime(subMinutes(time, FLIGHT_OPTS.SortieDefaults[flight.sortieType].cr * 60));
        }
        setTakeoffTime(time);
    }

    const onCancel = useCallback(() => {
        setEditTimes(false);
        setOShowTime(utcDate(flight.oShowTime));
        setEShowTime(utcDate(flight.eShowTime));
        setCRTime(utcDate(flight.crTime));
        setTakeoffTime(utcDate(flight.takeoffTime));
        setLandTime(utcDate(flight.landTime));
    }, [flight])

    useEffect(() => {
        if (!editTimes) {
            onCancel();
        }
    }, [editTimes, flight, onCancel])

    return (

        <Paper className={classes.flightPaper}>
            <FabControls
                edit={editTimes}
                setEdit={setEditTimes}
                onCancel={onCancel}
                onCommit={onCommit}
                hidden={editHidden}
            />
            <Container className={classes.flightContainer}>
                <Grid container>
                    <Grid item xs={12}><b>{title ? title : "Times"}</b></Grid>
                    <Hidden xsUp={!editTimes}>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        color={"primary"}
                                        checked={autoShowTimes}
                                        icon={<CheckBoxOutlineBlank fontSize="small"/>}
                                        checkedIcon={<CheckBox fontSize="small"/>}
                                        onClick={updateDefaults}
                                    />
                                }
                                label={"Use Defaults"}
                            />
                        </Grid>
                    </Hidden>
                    <Grid item xs={12}>
                        <TimeSelector
                            label={"O Show:"}
                            value={oShowTime}
                            onChange={(value) => {setOShowTime(utcDate(value))}}
                            disabled={!editTimes || autoShowTimes}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TimeSelector
                            label={"E Show:"}
                            value={eShowTime}
                            onChange={(value) => {setEShowTime(utcDate(value))}}
                            disabled={!editTimes || autoShowTimes}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TimeSelector
                            label={"Crew Ready:"}
                            value={crTime}
                            onChange={(value) => {setCRTime(utcDate(value))}}
                            disabled={!editTimes || autoShowTimes}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TimeSelector
                            label={"Takeoff:"}
                            value={takeoffTime}
                            onChange={(value) => {changeTakeoffTime(utcDate(value), autoShowTimes)}}
                            disabled={!editTimes}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TimeSelector
                            label={"Land:"}
                            value={landTime}
                            onChange={(value) => {setLandTime(utcDate(value))}}
                            disabled={!editTimes}
                        />
                    </Grid>
                </Grid>
            </Container>
        </Paper>
    )
}

export default compose(
    withStyles(appStyles),
    withAuthorization(isMember),
)(FlightTimes)