import {
    Button,
    Container,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Fab, Grid,
    Hidden,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    withStyles,
} from "@material-ui/core";
import {EVENT_STATUS, isMember, ROLES} from "../../constants";
import React, {useEffect, useState} from "react";
import {compose} from "recompose";
import {appStyles} from "../../styles";
import {withAuthorization} from "../Session";
import {Add, Check, Clear} from "@material-ui/icons";
import EventTypeSelector from "../Forms/EventTypeSelector";
import {DefaultEvent, SortieEvents} from "./flightOptions";
import {ConfirmDialog, HoverControls, StatusRadioSelector, TimeSelector} from "../Forms";
import fb from "firebase";
import {firestoreAutoId} from "../Firebase";
import {viewDate} from "../Session/utils";
import {AuditableChange} from "../Audit";

const FlightEvents = (props) => {
    const {authUser, classes, schedLock, adminLock, events, flightHasStatus, flightId, takeoffTime, landTime} = props;

    const [addEvent, setAddEvent] = useState(false);
    const [eventEditing, setEventEditing] = useState(false);

    const canEdit = !adminLock && (!schedLock || authUser.role === ROLES.ADMIN) && authUser.role > ROLES.MEMBER && !flightHasStatus;
    const sortieOver = new Date(landTime) < new Date()

    return (
        <>
            <Paper className={classes.flightPaper}>
                <Hidden xsUp={!canEdit}>
                    <Fab className={classes.fab}
                         color="default"
                         aria-label="add"
                         size={"small"}
                         onClick={() => setAddEvent(true)}
                    >
                        <Add />
                    </Fab>
                </Hidden>
                <Hidden xsUp={canEdit}>
                    <Button disabled={true}/>
                </Hidden>
                <AddEvent
                    isOpen={addEvent}
                    flightId={flightId}
                    takeoffTime={takeoffTime}
                    landTime={landTime}
                    onClose={() => setAddEvent(false)}
                    />
                <TableContainer component={Container} className={classes.flightContainer}>
                    <b>Events</b>
                    <Table className={classes.table} size={"small"}>
                        <TableHead>
                            <Grid container component={TableRow}>
                                <Grid item component={TableCell} xs={8}>Name</Grid>
                                <Hidden xsUp={sortieOver || flightHasStatus}>
                                    <Grid item component={TableCell} xs={2} align={"right"}>Location</Grid>
                                    <Grid item component={TableCell} xs={2} align={"right"}>Times</Grid>
                                </Hidden>
                                <Hidden xsUp={!sortieOver && !flightHasStatus}>
                                    <Grid item component={TableCell} xs={3} align={"center"}>Status</Grid>
                                    <Grid item component={TableCell} xs={1} align={"left"}></Grid>
                                </Hidden>
                            </Grid>
                        </TableHead>
                        <TableBody>
                            {events ?
                                Object.entries(events).sort().map(([id, event]) => {
                                    return(
                                        sortieOver || flightHasStatus ?
                                        <>
                                            <FlightEventStatusRow
                                                flightId={flightId}
                                                eventId={id}
                                                event={event}
                                                canEdit={authUser.role > ROLES.MEMBER}
                                            />
                                        </>
                                        :
                                        <>
                                            <FlightEventRow
                                                flightId={flightId}
                                                eventId={id}
                                                event={event}
                                                canEdit={!eventEditing && canEdit}
                                                lockEditing={setEventEditing}
                                            />
                                        </>
                                        );
                                    })
                                :
                                <>
                                    <Grid container component={TableRow}>
                                        <Grid item component={TableCell} xs={12}>None Scheduled</Grid>
                                    </Grid>
                                </>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </>
    )
}

const FlightEventRow = compose(withAuthorization(isMember),withStyles(appStyles))((props) => {
    const {authUser, classes, flightId, eventId, event, canEdit, lockEditing, firebase} = props;

    const [confirmDelete, setConfirmDelete] = useState(false);
    const [editing, setEditing] = useState(false);
    const [type, setType] = useState(event.type);
    const [location, setLocation] = useState(event.location);
    const [blockStart, setBlockStart] = useState(event.blockStart);
    const [blockEnd, setBlockEnd] = useState(event.blockEnd);

    const startEditing = () => {
        lockEditing(true);
        setType(event.type);
        setLocation(event.location);
        setBlockStart(event.blockStart);
        setBlockEnd(event.blockEnd);
        setEditing(true);
    }

    const stopEditing = () => {
        setEditing(false);
        lockEditing(false);
    }

    const deleteEvent = () => {
        setConfirmDelete(false);
        const delta = {
            events: {
                [`${eventId}`]: fb.firestore.FieldValue.delete()
            }
        }
        AuditableChange(authUser.uid, firebase.flight(flightId), { events: {[`${eventId}`]: event} }, delta,
            `Deleted ${event.type} event`)
    }

    const saveEvent = () => {
        stopEditing();
        const delta = {
            events: {
                [`${eventId}`]: {
                    type: type,
                    location: location,
                    blockStart: blockStart,
                    blockEnd: blockEnd,
                }
            }
        }
        AuditableChange(authUser.uid, firebase.flight(flightId), {events: {}}, delta,
            `Edited ${event.type} event`)
    }

    return (
        <>
            <Grid container component={TableRow} className={classes.hoverRow}>
                <Hidden xsUp={editing}>
                    <Grid item component={TableCell} xs={8}>{SortieEvents[event.type].name}</Grid>
                    <Grid item component={TableCell} xs={3} align={"right"}>
                        {event.location === "" ? "TBD" : event.location}
                    </Grid>
                    <Grid item component={TableCell} xs={1} className={classes.noPadding}>
                        <HoverControls
                            content={<>
                                {viewDate(event.blockStart, "HHmm")}
                                -
                                {viewDate(event.blockEnd, "HHmm")}
                                </>}
                            blocked={!canEdit}
                            onEdit={startEditing}
                            onDelete={() => setConfirmDelete(true)}
                        />
                    </Grid>
                </Hidden>
                <Hidden xsUp={!editing}>
                    <TableCell colSpan={3}>
                        <Grid container>
                            <Grid item xs={12} sm={3}>
                                <EventTypeSelector value={type} onChange={setType} />
                            </Grid>
                            <Grid item xs={12} sm={5}>
                                <TextField
                                    label={"location"}
                                    value={location}
                                    onChange={(event) => setLocation(event.target.value)}
                                />
                            </Grid>
                            <Grid item xs={6} sm={2}>
                                <TimeSelector label={"Block Start"} value={blockStart} onChange={setBlockStart} />
                            </Grid>
                            <Grid item xs={6} sm={2}>
                                <TimeSelector label={"Block End"} value={blockEnd} onChange={setBlockEnd} />
                            </Grid>
                            <Grid item xs={12} >
                                <IconButton className={classes.alignRight} onClick={saveEvent} color={"primary"}>
                                    <Check />
                                </IconButton>
                                <IconButton className={classes.alignRight} onClick={stopEditing} color={"secondary"}>
                                    <Clear />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </TableCell>
                </Hidden>
            </Grid>
            <ConfirmDialog
                open={confirmDelete}
                title={"Confirm"}
                text={`Are you sure you want to remove ${SortieEvents[event.type].name} from this flight?`}
                onCancel={() => setConfirmDelete(false)}
                onConfirm={deleteEvent}
            />
        </>
    );
});

const FlightEventStatusRow = compose(withAuthorization(isMember),withStyles(appStyles))((props) => {
    const {authUser, classes, flightId, eventId, event, canEdit, firebase} = props;

    const [editing, setEditing] = useState(false)

    useEffect(() => {
        const needsEditing = event.status === undefined || EVENT_STATUS[event.status] === EVENT_STATUS.UNKNOWN
        if (needsEditing !== editing) {
            setEditing(needsEditing)
        }
    // Intentionally only want to run this effect when the event changes.
    // eslint-disable-next-line
    }, [event])
    const saveEventStatus = (eventStatus) => {
        const delta = {
            events: {
                [`${eventId}`]: {
                    type: event.type,
                    location: event.location,
                    blockStart: event.blockStart,
                    blockEnd: event.blockEnd,
                    status: eventStatus,
                }
            }
        }
        AuditableChange(authUser.uid, firebase.flight(flightId), {events: {}}, delta,
            `Edited ${event.type} event`)
        setEditing(false)
    }
    return (
        <>
            <Grid container component={TableRow} className={classes.hoverRow}>
                <Hidden xsUp={editing}>
                    <Grid item component={TableCell} xs={8}>{SortieEvents[event.type].name}</Grid>
                    <Grid item component={TableCell} xs={3} align={"center"}
                          className={EVENT_STATUS[event.status] !== EVENT_STATUS.COMPLETED ?
                              classes.eventStatusCancelled : classes.eventStatusCompleted}>
                        {EVENT_STATUS[event.status]}
                    </Grid>
                    <Grid item component={TableCell} xs={1} className={classes.noPadding}>
                        <HoverControls
                            content={<></>}
                            blocked={!canEdit}
                            onEdit={() => setEditing(true)}
                        />
                    </Grid>
                </Hidden>
                <Hidden xsUp={!editing}>
                    <TableCell colSpan={3} align={"left"}>
                        <Grid container>
                            <Grid item xs={10}>
                                <StatusRadioSelector
                                    eventLabel={SortieEvents[event.type].name}
                                    name="event-status-group"
                                    value={event.status}
                                    onChange={(event) => saveEventStatus(event.target.value)}
                                    />
                                    </Grid>
                            <Grid item xs={2}>
                                <HoverControls
                                    content={<></>}
                                    onCancel={() => setEditing(false)}
                                />
                            </Grid>
                        </Grid>
                    </TableCell>
                </Hidden>
            </Grid>
        </>
    );
});

const AddEvent = withAuthorization(isMember)((props) => {
    const {authUser, flightId, takeoffTime, landTime, onClose, isOpen, firebase} = props;
    const [eventType, setEventType] = useState(DefaultEvent);
    const [eventLocation, setEventLocation] = useState("");
    const [eventBlockStart, setEventBlockStart] = useState(takeoffTime);
    const [eventBlockEnd, setEventBlockEnd] = useState(landTime);

    const saveEvent = () => {
        const delta = {
            events: {
                [`${firestoreAutoId()}`]: {
                    type: eventType,
                    location: eventLocation,
                    blockStart: eventBlockStart,
                    blockEnd: eventBlockEnd,
                }
            }
        }
        AuditableChange(authUser.uid, firebase.flight(flightId), {events: {}}, delta,
            `Added ${eventType} event`)
        onClose();
    };

    return (
        <>
            <Dialog open={isOpen}>
                <DialogTitle onClose={onClose}>
                    Add Event
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <EventTypeSelector value={eventType} onChange={setEventType} />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label={"Location"}
                                value={eventLocation}
                                onChange={(event) => setEventLocation(event.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TimeSelector label={"Block Start"} value={eventBlockStart} onChange={setEventBlockStart} />
                        </Grid>
                        <Grid item xs={12}>
                            <TimeSelector label={"Block End"} value={eventBlockEnd} onChange={setEventBlockEnd} />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        color="default"
                        data-dismiss="modal"
                        type="button"
                        onClick={onClose}
                    >
                        Close
                    </Button>
                    <Button
                        color="primary"
                        type="button"
                        onClick={saveEvent}
                    >
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
})

export default compose(
    withStyles(appStyles),
    withAuthorization(isMember),
)(FlightEvents);