import React, { Component } from 'react';

import { withFirebase } from '../Firebase';
import {Button, Grid, Paper, TextField, withStyles} from "@material-ui/core";
import {appStyles} from "../../styles";
import {compose} from "recompose";

const INITIAL_STATE = {
    passwordOne: '',
    passwordTwo: '',
    error: null,
};

class PasswordChangeForm extends Component {
    constructor(props) {
        super(props);

        this.state = { ...INITIAL_STATE };
    }

    onSubmit = event => {
        const { passwordOne } = this.state;

        this.props.firebase
            .doPasswordUpdate(passwordOne)
            .then(() => {
                this.setState({ ...INITIAL_STATE });
            })
            .catch(error => {
                this.setState({ error });
            });

        event.preventDefault();
    };

    onChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    render() {
        const { passwordOne, passwordTwo, error } = this.state;
        const {classes} = this.props;
        const isInvalid =
            passwordOne !== passwordTwo || passwordOne === '';

        return (
            <Paper className={classes.paper}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <TextField
                            required
                            fullWidth
                            name="passwordOne"
                            value={passwordOne}
                            onChange={this.onChange}
                            type="password"
                            label="New Password"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            required
                            fullWidth
                            name="passwordTwo"
                            value={passwordTwo}
                            onChange={this.onChange}
                            type="password"
                            label="Confirm New Password"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button variant={"contained"} disabled={isInvalid} onClick={this.onSubmit}>
                            Reset My Password
                        </Button>
                    </Grid>
                    {error && <p>{error.message}</p>}
                </Grid>
            </Paper>
        );
    }
}

export default compose(
    withFirebase,
    withStyles(appStyles),
)(PasswordChangeForm);