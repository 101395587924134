import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';

import { SignUpLink } from '../SignUp';
import { withFirebase } from '../Firebase';
import {Button, Grid, Paper, TextField, Typography} from "@material-ui/core";
import {PasswordForgetLink} from "../PasswordForget";

import {appStyles} from "../../styles";
import withStyles from "@material-ui/core/styles/withStyles";

const SignInPage = (props) => {
    const {classes} = props;

    return (
        <>
            <Typography
                className={classes.pageTitle}
                variant={"h4"}>SignIn</Typography>
            <SignInForm/>
            <SignUpLink/>
        </>
    );
};

const INITIAL_STATE = {
    email: '',
    password: '',
    error: null,
};

class SignInFormBase extends Component {
    constructor(props) {
        super(props);

        this.state = { ...INITIAL_STATE };
    }

    onSubmit = event => {
        const { email, password } = this.state;

        this.props.firebase
            .doSignInWithEmailAndPassword(email, password)
            .then(() => {
                this.setState({ ...INITIAL_STATE });
                this.props.history.push("/");
            })
            .catch(error => {
                this.setState({ error });
            });

        event.preventDefault();
    };

    onChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    render() {
        const {classes} = this.props;
        const { email, password, error } = this.state;

        const isInvalid = password === '' || email === '';

        return (
            <Paper className={classes.paper} elevation={3}>

                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <TextField
                            required
                            name="email"
                            value={email}
                            onChange={this.onChange}
                            label="Email Address"
                            autoComplete={"email"}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            required
                            name="password"
                            value={password}
                            onChange={this.onChange}
                            type="password"
                            label={"Password"}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <PasswordForgetLink />
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            variant={"contained"}
                            disabled={isInvalid}
                            color={"primary"}
                            className={classes.button}
                            onClick={this.onSubmit}
                        >
                            Sign In
                        </Button>
                    </Grid>
                        {error && <p>{error.message}</p>}
                </Grid>
            </Paper>
        );
    }
}

const SignInForm = compose(
    withRouter,
    withFirebase,
    withStyles(appStyles),
)(SignInFormBase);

export default withStyles(appStyles)(SignInPage);

export { SignInForm };