
import {utcDate, viewDate} from "../Session/utils";
import fb from "firebase";
import {compose} from "recompose";
import withStyles from "@material-ui/core/styles/withStyles";
import {appStyles} from "../../styles";
import withAuthorization from "../Session/withAuthorization";
import {isMember} from "../../constants";
import {
    Container,
    Grid, Hidden, IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {ExpandLess, ExpandMore} from "@material-ui/icons";


export function AuditableChange(userId, objHandle, oldObj, delta, change, successCallback = null) {
    // Build change string
    if (oldObj === null) {
        // This is an add
        const deltaString = JSON.stringify(delta)
        const details = `Added ${deltaString}`
        delta.auditTrail = [{
            user: userId,
            change: change,
            timestamp: utcDate(new Date()).toISOString(),
            delta: details,
        }]
        objHandle.add(delta).then(() => {
            if (successCallback) {
                successCallback()
            }
        })
    } else if(delta === null) {
        // This is a remove
        // TODO(tim): Decide if we need to save off the audit log for deleted items.
        objHandle.delete().then(() => {
            if (successCallback) {
                successCallback()
            }
        })
    } else {
        // This is an edit
        let details = ""
        for (let p in delta) {
            if (delta.hasOwnProperty(p)) {
                if (delta[p] !== oldObj[p]) {
                    // TODO(tim): handle removed fields
                    const value = JSON.stringify(delta[p])
                    details += `Changed ${p} to ${value}\n`
                }
            }
        }
        const changeObj = {
            user: userId,
            change: change,
            timestamp: utcDate(new Date()).toISOString(),
            delta: details,
        }
        delta.auditTrail = fb.firestore.FieldValue.arrayUnion(changeObj)
        objHandle.set(delta, {merge: true}).then(() => {
            if (successCallback) {
                successCallback()
            }
        })

    }
    return true
}

const AuditView = (props) => {
    const {firebase, classes, auditTrail} = props
    const [users, setUsers] = useState({})
    const [expanded, setExpanded] = useState(false)

    useEffect(() => {
        return firebase.users().onSnapshot(snapshot => {
            let users = {}
            snapshot.forEach(doc => (users[doc.id] = {...doc.data()}));
            setUsers(users);
        });
    }, [firebase]);

    return (
        <Paper className={classes.flightPaper}>
            <TableContainer component={Container} className={classes.auditContainer}>
                <b>Change History</b>
                {
                    expanded ?
                        <IconButton className={classes.fab} color={"default"} onClick={() => setExpanded(false)}>
                            <ExpandMore/>
                        </IconButton>
                        :
                        <IconButton className={classes.fab} color={"default"} onClick={() => setExpanded(true)}>
                            <ExpandLess/>
                        </IconButton>

                }
                <Hidden xsUp={!expanded}>
                    <Table className={classes.table} size={"small"}>
                        <TableHead>
                            <Grid container component={TableRow}>
                                <Grid item component={TableCell} xs={3} colSpan={3}>Made By</Grid>
                                <Grid item component={TableCell} xs={3} colSpan={3}>Time</Grid>
                                <Grid item component={TableCell} xs={6} colSpan={6}>Change</Grid>
                            </Grid>
                        </TableHead>
                        <TableBody>
                            {
                                auditTrail ?
                                    <>
                                    {auditTrail.map((change) => {
                                        return <AuditRow key={change} change={change} users={users}/>
                                    })}
                                    </>
                                :
                                    <>
                                        <Grid container component={TableRow}>
                                            <Grid item component={TableCell} xs={12} colSpan={12}>No History</Grid>
                                        </Grid>
                                    </>
                            }
                        </TableBody>
                    </Table>
                </Hidden>
            </TableContainer>
        </Paper>
    )
}

const AuditRow = (props) => {
    const {users, change} = props
    return (
        <>
            <Grid container component={TableRow}>
                <Grid item component={TableCell} xs={3} colSpan={3}>{
                    users && users[change.user] !== undefined ?
                        users[change.user].username
                        :
                        "unknown"
                }</Grid>
                <Grid item component={TableCell} xs={3} colSpan={3}>{viewDate(change.timestamp, "yyyy-MM-dd HH:mm")}</Grid>
                <Grid item component={TableCell} xs={6} colSpan={6}>{change.change}</Grid>
            </Grid>
        </>
    )
}

export default compose(
    withStyles(appStyles),
    withAuthorization(isMember),
)(AuditView);