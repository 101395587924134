import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";

import { withAuthorization } from '../Session';
import {
    Grid,
    Hidden,
    withStyles,
} from "@material-ui/core";
import {Alert} from '@material-ui/lab';
import {appStyles} from "../../styles";
import {addHours} from "date-fns";
import {ROUTES} from "../../App";
import {EVENT_STATUS, isMember, ROLES, SORTIE_LOCKS} from "../../constants";
import {compose} from "recompose";
import CrewMembers from "./crewMembers";
import FlightEvents from "./flightEvents";
import FlightTimes from "./flightTimes";
import FlightData from "./flightData";
import FlightConfig from "./flightConfig";
import {AuditableChange} from "../Audit";
import AuditView from "../Audit";
import {utcDate} from "../Session/utils";
import FlightTextArea from "./flightTextArea";

const FlightPage = (props) => {
    const {classes, history, firebase, authUser} = props;
    const [loading, setLoading] = useState(true);
    // Sortie Details
    const {flightId} = useParams();
    const [flight, setFlight] = useState({});
    const [notes, setNotes] = useState("");
    const [editNotes, setEditNotes] = useState(false);
    const [postMission, setPostMission] = useState("");
    const [editPostMission, setEditPostMission] = useState(false);
    const [userLock, setUserLock] = useState(false);
    const [schedLock, setSchedLock] = useState(false);
    const [adminLock, setAdminLock] = useState(false);
    const [hideEvents, setHideEvents] = useState(false);
    const [hideConfig, setHideConfig] = useState(false);

    const canEditData = !adminLock && (!schedLock || authUser.role === ROLES.ADMIN) && authUser.role > ROLES.MEMBER;
    const flightHasStatus = flight.status !== undefined && EVENT_STATUS[flight.status] !== EVENT_STATUS.UNKNOWN
    const canEdit = canEditData && !flightHasStatus
    const postMissionVisible = utcDate(flight.takeoffTime) < utcDate(new Date())
    const isPilotOnFlight = flight.crew && Object.keys(flight.crew).includes(authUser.uid) &&
        flight.crew[authUser.uid].crewPosition === "P"
    const canEditPostMission = isPilotOnFlight || authUser.role > ROLES.MEMBER

    const setValues = (doc) => {
        const {takeoffTime, notes, postMission} = doc.data();
        setFlight(doc.data());
        setHideEvents(doc.data().sortieType === "GT" ||
            doc.data().sortieType === "Sim" ||
            doc.data().sortieType === "Static");
        setHideConfig(doc.data().sortieType === "Sim");
        setNotes(notes);
        setPostMission(postMission)
        if (new Date(takeoffTime) < addHours(new Date(), SORTIE_LOCKS.USER)) {
            setUserLock(true);
        }
        if (new Date(takeoffTime) < addHours(new Date(), SORTIE_LOCKS.SCHED)) {
            setSchedLock(true);
        }
        if (new Date(takeoffTime) < addHours(new Date(), SORTIE_LOCKS.ADMIN)) {
            setAdminLock(true);
        }

        setLoading(false);
    }

    useEffect(() => {
        setLoading(true);
        return firebase
            .flight(flightId).onSnapshot(doc => {
                if (doc.exists) {
                    setValues(doc);
                } else {
                    history.push(ROUTES.SCHEDULE.path);
                }
            });
    }, [firebase, history, flightId]);

    const onCancelEdit = () => {
        firebase.flight(flightId).get().then((doc) => {
            if (doc.exists) {
                setValues(doc);
            }
        });
    }

    const onSavePostMission = () => {
        AuditableChange(authUser.uid, firebase.flight(flightId), flight, {postMission: postMission},
            "Changed the post mission comments")
    }

    const onSaveNotes = () => {
        AuditableChange(authUser.uid, firebase.flight(flightId), flight, {notes: notes},
            "Changed the notes")
    }

    return (
        <Hidden xsUp={loading}>
            <Grid container
                className={classes.grid}
                spacing={3}
                justifyContent="center"
            >
                <Hidden xsUp={!flightHasStatus}>
                    <Grid item xs={12}>
                        <Alert
                            severity={EVENT_STATUS[flight.status] === EVENT_STATUS.COMPLETED ? "success" : "error"}>
                            {EVENT_STATUS[flight.status]}
                        </Alert>
                    </Grid>
                </Hidden>
                <Grid item xs={12}>
                    <FlightData
                        flightId={flightId}
                        flight={flight}
                        editHidden={!canEditData}
                    />
                </Grid>
            <Grid item md={hideConfig ? 6 : 3} sm={hideConfig ? 12 : 6} xs={12}>
                <FlightTimes
                    flightHandle={firebase.flight(flightId)}
                    flight={flight}
                    editHidden={!canEdit}
                />
            </Grid>
                <Hidden xsUp={hideConfig}>
                <Grid item md={3} sm={6} xs={12}>
                    <FlightConfig
                        flightHandle={firebase.flight(flightId)}
                        flight={flight}
                        editHidden={!canEdit}
                    />
                </Grid>
                </Hidden>
                <Grid item md={6} sm={12} xs={12}>
                    <CrewMembers
                        eventHandle={firebase.flight(flightId)}
                        isFlight={true}
                        // Or in the status so the flight locks if the status is set.
                        userLock={userLock || flightHasStatus}
                        schedLock={schedLock || flightHasStatus}
                        adminLock={adminLock || flightHasStatus}
                    />
                </Grid>
                <Hidden xsUp={hideEvents}>
                <Grid item xs={12}>
                    <FlightEvents
                        flightId={flightId}
                        events={flight.events}
                        takeoffTime={flight.takeoffTime}
                        landTime={flight.landTime}
                        // Or in the status so the flight locks if the status is set.
                        flightHasStatus={flightHasStatus}
                        schedLock={schedLock}
                        adminLock={adminLock}
                    />
                </Grid>
                </Hidden>
                <Hidden xsUp={!postMissionVisible}>
                    <Grid item xs={12}>
                        <FlightTextArea
                            label={"Post Mission Comments"}
                            value={postMission}
                            setValue={setPostMission}
                            edit={editPostMission}
                            setEdit={setEditPostMission}
                            onCancel={onCancelEdit}
                            onSave={onSavePostMission}
                            canEdit={canEditPostMission}
                        />
                    </Grid>
                </Hidden>
                <Grid item xs={12}>
                    <FlightTextArea
                        label={"Scheduler Notes"}
                        value={notes}
                        setValue={setNotes}
                        edit={editNotes}
                        setEdit={setEditNotes}
                        onCancel={onCancelEdit}
                        onSave={onSaveNotes}
                        canEdit={canEdit}
                    />
                </Grid>
                <Grid item xs={12}>
                    <AuditView auditTrail={flight.auditTrail}/>
                </Grid>
            </Grid>
        </Hidden>
    );
}

export default compose(
    withStyles(appStyles),
    withAuthorization(isMember),
)(FlightPage);