import React from "react";
import {Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button} from "@material-ui/core";

const ConfirmDialog = (props) => {
    const {open, title, text, onCancel, onConfirm} = props;

    return (
      <>
          <Dialog
              open={open}
              onClose={onCancel}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
          >
              <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
              <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                      {text}
                  </DialogContentText>
              </DialogContent>
              <DialogActions>
                  <Button onClick={onCancel} color="secondary">
                      Cancel
                  </Button>
                  <Button onClick={onConfirm} color="primary" autoFocus>
                      OK
                  </Button>
              </DialogActions>
          </Dialog>
      </>
    );
}

export default ConfirmDialog