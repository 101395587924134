import React, { Component } from 'react';
import { Link as ReactLink } from 'react-router-dom';

import { withFirebase } from '../Firebase';
import {ROUTES} from '../../App';
import {Button, Paper, Grid, Link, TextField, Typography, withStyles} from "@material-ui/core";
import {appStyles} from "../../styles";
import {compose} from "recompose";

const PasswordForgetPage = (props) => (
    <>
        <Typography className={props.classes.pageTitle} variant={"h4"}>Forgot Password</Typography>
        <PasswordForgetForm />
    </>
);

const INITIAL_STATE = {
    email: '',
    error: null,
};

class PasswordForgetFormBase extends Component {
    constructor(props) {
        super(props);

        this.state = { ...INITIAL_STATE };
    }

    onSubmit = event => {
        const { email } = this.state;

        this.props.firebase
            .doPasswordReset(email)
            .then(() => {
                this.setState({ ...INITIAL_STATE });
            })
            .catch(error => {
                this.setState({ error });
            });

        event.preventDefault();
    };

    onChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    render() {
        const { email, error } = this.state;
        const {classes} = this.props;
        const isInvalid = email === '';

        return (
            <Paper className={classes.paper} elevation={3}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <TextField
                          required
                          name="email"
                          value={this.state.email}
                          onChange={this.onChange}
                          type="text"
                          label="Email Address"
                          fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            className={classes.button}
                            variant={"contained"}
                            color={"primary"}
                            disabled={isInvalid}
                            onClick={this.onSubmit}>
                            Reset My Password
                        </Button>
                    </Grid>
                    {error && <p>{error.message}</p>}
                </Grid>
            </Paper>
        );
    }
}

const PasswordForgetLink = () => (
    <Link component={ReactLink} to={ROUTES.PW_FORGET.path}>Forgot Password?</Link>
);

export default withStyles(appStyles)(PasswordForgetPage);

const PasswordForgetForm = compose(
    withFirebase,
    withStyles(appStyles),
)(PasswordForgetFormBase);

export { PasswordForgetForm, PasswordForgetLink };