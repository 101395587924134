import {appStyles} from "../../styles";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {InputAdornment, withStyles} from "@material-ui/core";
import {Event} from "@material-ui/icons";
import React from "react";

const DateSelector = (props) => {
    const {label, value, onChange, minDate, maxDate, disabled} = props;
    return (
        <>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                    label={label}
                    className="form-control text-center datepicker bg-white text-dark"
                    value={value}
                    onChange={onChange}
                    disabled={disabled}
                    InputProps={{
                        endAdornment: <InputAdornment position={"end"}><Event/></InputAdornment>
                    }}
                    minDate={minDate}
                    maxDate={maxDate}
                />
            </MuiPickersUtilsProvider>
        </>
    )
}

export default withStyles(appStyles)(DateSelector);