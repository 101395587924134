import {ConfirmDialog, DateSelector, FabControls, OptionSelector, StatusRadioSelector} from "../Forms";
import {Grid, Hidden, Paper, withStyles} from "@material-ui/core";
import {adjustTime, utcDate} from "../Session/utils";
import React, {useCallback, useEffect, useState} from "react";
import {compose} from "recompose";
import {appStyles} from "../../styles";
import {withAuthorization} from "../Session";
import {EVENT_STATUS, isMember, ROLES} from "../../constants";
import * as FLIGHT_OPTS from "./flightOptions";
import {ROUTES} from "../../App";
import {SelectSortie} from "../Forms/OptionSelector";
import {AuditableChange} from "../Audit";
import fb from "firebase";

const FlightData = (props) => {

    const {authUser, classes, firebase, flightId, flight, editHidden} = props;
    const [editSortie, setEditSortie] = useState(false);
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [sortieType, setSortieType] = useState(FLIGHT_OPTS.DefaultType);
    const [takeoffTime, setTakeoffTime] = useState(new Date());
    const [status, setStatus] = useState(undefined);

    const canSetStatus = authUser.role > ROLES.MEMBER
    const statusNeeded = utcDate(flight.takeoffTime) < utcDate(new Date()) &&
        (flight.status === undefined || EVENT_STATUS[flight.status] === EVENT_STATUS.UNKNOWN)
    const editStatus = statusNeeded && canSetStatus
    const editData = editSortie && !editHidden

    const onCommit = useCallback(() => {
        let events = {}
        let statusToSave = status
        if (status === undefined || EVENT_STATUS[status] === EVENT_STATUS.UNKNOWN) {
            statusToSave = fb.firestore.FieldValue.delete();
        }
        const eventStatus = (status !== undefined && EVENT_STATUS[status] !== EVENT_STATUS.UNKNOWN &&
            EVENT_STATUS[status] !== EVENT_STATUS.COMPLETED) ? status : fb.firestore.FieldValue.delete()
        Object.keys(flight.events).forEach((eventId) => {
            events[eventId] = {
                ...flight.events[eventId],
                status: eventStatus,
            }
        })
        let delta = {
            sortieType: sortieType,
            oShowTime: adjustTime(takeoffTime, utcDate(flight.oShowTime)),
            eShowTime: adjustTime(takeoffTime, utcDate(flight.eShowTime)),
            crTime: adjustTime(takeoffTime, utcDate(flight.crTime)),
            takeoffTime: adjustTime(takeoffTime, utcDate(flight.takeoffTime)),
            landTime: adjustTime(takeoffTime, utcDate(flight.landTime, true)),
            status: statusToSave,
            events: events,
        }
        AuditableChange(authUser.uid, firebase.flight(flightId), flight, delta,
            `Changed the flight details`)
    }, [authUser.uid, firebase, flightId, sortieType, takeoffTime, flight, status]);

    const onCancel = useCallback(() => {
        setEditSortie(false);
        setTakeoffTime(utcDate(flight.takeoffTime));
        setSortieType(flight.sortieType);
        setStatus(flight.status);
    }, [flight]);

    const onDelete = () => {
        setConfirmDelete(false);
        AuditableChange(authUser.uid, firebase.flight(flightId), flight, null, null,
            () => { props.history.push(ROUTES.SCHEDULE.path) })
    };

    useEffect(() => {
        if (statusNeeded && (status !== undefined && EVENT_STATUS[status] !== EVENT_STATUS.UNKNOWN)) {
            onCommit();
        }
    }, [onCommit, flight, status, statusNeeded])

    useEffect(() => {
        if (!editSortie) {
            onCancel();
        }
    }, [editSortie, flight, onCancel]);

    return (
        <Paper className={classes.flightPaper}>
            <FabControls
                edit={editSortie}
                setEdit={setEditSortie}
                onCancel={onCancel}
                onCommit={onCommit}
                onDelete={editHidden ? undefined : () => {setConfirmDelete(true)}}
                hidden={editHidden || editStatus}
            />
            <ConfirmDialog
                title={"Warning"}
                text={"Are you sure you want to delete this flight?"}
                open={confirmDelete}
                onCancel={() => setConfirmDelete(false)}
                onConfirm={onDelete}
            />
            <Grid container className={classes.flightContainer} justifyContent={"space-evenly"}>
                <Grid item xs={12} sm={8}>
                    <DateSelector
                        label={"Date"}
                        value={takeoffTime}
                        onChange={(value) => {setTakeoffTime(utcDate(adjustTime(value, takeoffTime)))}}
                        disabled={!editData}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <OptionSelector value={sortieType} selectType={SelectSortie} onChange={setSortieType} disabled={!editData}/>
                </Grid>
                <Hidden xsUp={!editSortie && !editStatus}>
                    <Grid item xs={12}>
                        <StatusRadioSelector
                            name="flight-status-group"
                            value={status}
                            allowUnset={!statusNeeded}
                            hideComplete={!statusNeeded}
                            onChange={(event) => {
                                setStatus(event.target.value);
                            }}
                        />
                    </Grid>
                </Hidden>
            </Grid>
        </Paper>
    )
};

export default compose(
    withStyles(appStyles),
    withAuthorization(isMember),
)(FlightData)