import React, {useEffect, useState} from "react";
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';

import {withAuthorization} from "../Session";
import {Hidden, Paper, Typography, withStyles} from "@material-ui/core";
import FlightEditor from "../Flights/addFlight";
import {EVENT_STATUS, isMember, ROLES, showTime, WEEK_DAYS} from "../../constants";
import {compose} from "recompose";
import {appStyles, appTheme} from "../../styles";
import withSessionData from "../Session/withSessionData";
import {SortieDefaults} from "../Flights/flightOptions";
import {viewDate, zonedDate} from "../Session/utils";

const SchedulePage = (props) => {
    const {classes, sessionData} = props;
    const [flightsLoading, setFlightsLoading] = useState(false);
    const [flights, setFlights] = useState({});
    const [cnxFlights, setCnxFlights] = useState({});
    const [tripsLoading, setTripsLoading] = useState(false);
    const [trips, setTrips] = useState([]);
    const [flightEditorModal, setFlightEditorModal] = useState(false);

    const loading = flightsLoading || tripsLoading;

    useEffect(() => {
        setTripsLoading(true);
        return props.firebase
            .trips()
            .orderBy('xcd.takeoffTime', 'asc')
            .onSnapshot(snapshot => {
                if (snapshot.size) {
                    let trips = [];

                    snapshot.forEach(doc =>
                        trips.push({
                            trip: {...doc.data()},
                            uid: doc.id,
                            title: doc.data().location,
                            start: new Date(doc.data().xcd.takeoffTime),
                            end: new Date(doc.data().xcr.landTime),
                        }),
                    );

                    setTrips(trips.reverse());
                    setTripsLoading(false);
                } else {
                    setTrips([])
                    setTripsLoading(false)
                }
            });
    }, [props.firebase])

    useEffect(() => {
        setFlightsLoading(true);
        return props.firebase.flights()
                .where("takeoffTime", ">=", sessionData.startDate.toISOString())
                .where("takeoffTime", "<=", sessionData.endDate.toISOString())
                .orderBy('takeoffTime', 'asc')
                .onSnapshot(snapshot => {
                    if (snapshot.size) {
                        let flights = {};
                        let cnxFlights = {};
                        snapshot.forEach(doc => {
                            let type = doc.data().sortieType;
                            let status = doc.data().status;
                            let cancelled = status !== undefined && EVENT_STATUS[status] !== EVENT_STATUS.UNKNOWN && EVENT_STATUS[status] !== EVENT_STATUS.COMPLETED;
                            if (cancelled) {
                                if (!cnxFlights[type]) {
                                    cnxFlights[type] = [];
                                }
                                cnxFlights[type].push({
                                    flight: {...doc.data()},
                                    uid: doc.id,
                                    title: doc.data().sortieType,
                                    start: zonedDate(doc.data().takeoffTime),
                                    end: zonedDate(doc.data().landTime),
                                    showTime: showTime(props.authUser, doc.data())
                                });

                            } else {
                                if (!flights[type]) {
                                    flights[type] = [];
                                }
                                flights[type].push({
                                    flight: {...doc.data()},
                                    uid: doc.id,
                                    title: doc.data().sortieType,
                                    start: zonedDate(doc.data().takeoffTime),
                                    end: zonedDate(doc.data().landTime),
                                    showTime: showTime(props.authUser, doc.data())
                                });
                            }
                        });
                        Object.keys(flights).map((flightType) => flights[flightType].reverse());
                        Object.keys(cnxFlights).map((flightType) => cnxFlights[flightType].reverse());
                        setFlights(flights);
                        setCnxFlights(cnxFlights);
                        setFlightsLoading(false);
                    } else {
                        setFlights([]);
                        setCnxFlights([]);
                        setFlightsLoading(false);
                    }
                });
    }, [props.firebase, props.authUser, sessionData.startDate, sessionData.endDate])

    const editFlight = (eventInfo) => {
        let {flight, trip} = eventInfo;
        if (trip) {
            props.history.push("/trip/" + eventInfo.uid);
        }
        if (flight) {
            props.history.push("/flight/" + eventInfo.uid);
        }
    };

    const addNewFlight = (dateInfo) => {
        if (props.authUser.role < ROLES.SCHEDULER) return;
        setFlightEditorModal(dateInfo.date);
    };

    const getEventSources = () => {
        let eventSources = [{
            events: trips,
            backgroundColor: appTheme.palette.grey.A700,
            borderColor: appTheme.palette.grey.A700,
            textColor: appTheme.palette.grey["50"],
        }];
        Object.keys(flights).map((flightType) =>
            eventSources.push({
                events: flights[flightType],
                backgroundColor: SortieDefaults[flightType].bgColor,
                borderColor: SortieDefaults[flightType].bgColor,
                textColor: SortieDefaults[flightType].textColor,
                display: "block",
            })
        );
        Object.keys(cnxFlights).map((flightType) =>
            eventSources.push({
                events: cnxFlights[flightType],
                backgroundColor: 'white',
                borderColor: SortieDefaults[flightType].bgColor,
                textColor: SortieDefaults[flightType].textColor,
                display: "block",
            })
        );
        return eventSources;
    }

    // Set class for display mode
    let paperClass = sessionData.displayMode ? classes.calendarPaper : classes.paper
    let mainClass = sessionData.displayMode ? classes.calendarLayout : classes.layout
    return (
        <main className={mainClass}>
            <Paper className={paperClass}>
                <Hidden xsUp={loading}>
                    <FullCalendar
                        plugins={[ dayGridPlugin, interactionPlugin ]}
                        initialView={sessionData.calendarView}
                        initialDate={sessionData.currentDate}
                        datesSet={(calendarInfo) => {
                           sessionData.calendarUpdate(calendarInfo);
                        }}
                        firstDay={WEEK_DAYS.MONDAY}
                        customButtons={{
                            toggleDisplayMode: {
                                text: sessionData.displayMode ? 'Normal' : 'Display',
                                click: function () {
                                    sessionData.setDisplayMode(!sessionData.displayMode)
                                }
                            }
                        }}
                        titleFormat={{year: 'numeric', month: 'short'}}
                        headerToolbar={{
                            right: sessionData.displayMode ?
                                'prev,next dayGridMonth,dayGridWeek toggleDisplayMode' :
                                'prev,next toggleDisplayMode',
                        }}
                        footerToolbar={{
                            right: sessionData.displayMode ? '' : 'dayGridMonth,dayGridWeek'
                        }}
                        height={sessionData.displayMode ? window.innerHeight - 50 : null}
                        eventSources={getEventSources()}
                        eventContent={Flight}
                        eventClick={(clickInfo) => editFlight(clickInfo.event.extendedProps)}
                        dateClick={(dateInfo) => addNewFlight(dateInfo)}
                    />
                </Hidden>
                <FlightEditor
                    openDate={flightEditorModal}
                    toggle={() => setFlightEditorModal(!flightEditorModal)}
                />
            </Paper>
        </main>
    );
}

const Flight = (props) => {
    const {flight, trip, showTime} = props.event.extendedProps;
    if (trip) {
        return (
            <div align={"center"}>
                <Typography variant={"body2"}><b>{props.event.title}</b></Typography>
            </div>
        )
    } else {
        return (
            <div style={{paddingLeft: appTheme.spacing(0.5)}}>
                <Typography variant={"body2"}><b>{props.event.title}</b>
                    {props.view.type === "dayGridWeek" ?
                        <>
                            <br/>
                            Show:<br/>
                            <b>{viewDate(new Date(showTime), "HHmm")}</b><br/>
                            T/O:<br/>
                            <b>{viewDate(new Date(flight.takeoffTime), "HHmm")}</b><br/>
                            Land:<br/>
                            <b>{viewDate(new Date(flight.landTime), "HHmm")}</b><br/>
                        </>
                        :
                        <>: {viewDate(new Date(showTime), "HHmm")}</>}
                </Typography>
            </div>
        );
    }
};

export default compose(
    withStyles(appStyles),
    withAuthorization(isMember),
    withSessionData,
)(SchedulePage);