import {FormControl, InputLabel, MenuItem, Select} from "@material-ui/core";
import React from "react";

const Dropdown = (props) => {
    const {options, value, label, onChange} = props;

    return (
        <FormControl>
            <InputLabel>{label}</InputLabel>
            <Select
                value={value}
                onChange={(event) => onChange(event.target.value)}>
                {Object.entries(options).map(([key, value]) => (
                    <MenuItem value={key}>{value}</MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default Dropdown;
