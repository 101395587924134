import React from "react";

import {isMember} from "../../constants";
import {withAuthorization} from '../Session';
import {PasswordForgetForm} from "../PasswordForget";
import PasswordChangeForm from "../PasswordChange";
import {Typography} from "@material-ui/core";

const AccountPage = (props) => {
    return (
        <>
            <Typography variant={"h4"}>Account: {props.authUser.email}</Typography>
            <Typography variant={"h5"}>Request Password Reset</Typography>
            <PasswordForgetForm />
            <Typography variant={"h5"}>Reset Password</Typography>
            <PasswordChangeForm />
        </>
    );
};

export default withAuthorization(isMember)(AccountPage);