import {compose} from "recompose";
import {Container, Grid, Paper, TextField, withStyles} from "@material-ui/core";
import {appStyles} from "../../styles";
import {withAuthorization} from "../Session";
import {FabControls} from "../Forms";
import React, {useCallback, useEffect, useState} from "react";
import {isMember} from "../../constants";
import OptionSelector, {SelectTail} from "../Forms/OptionSelector";
import InputAdornment from "@material-ui/core/InputAdornment";
import {AuditableChange} from "../Audit";

const FlightConfig = (props) => {
    const {authUser, classes, flightHandle, flight, editHidden, title} = props;
    const [editConfig, setEditConfig] = useState(false);
    const [tail, setTail] = useState("");
    const [config, setConfig] = useState("");
    const [fuel, setFuel] = useState("");
    const [load, setLoad] = useState("");

    const onCommit = () => {
        let flightData = flight;
        if (tail) flightData.tail = tail;
        if (config !== undefined) flightData.config = config;
        if (fuel !== undefined) flightData.fuel = fuel;
        if (load !== undefined) flightData.load = load;
        AuditableChange(authUser.uid, flightHandle, flight, flightData,
            `Changed the flight configuration`)
    }

    const onCancel = useCallback(() => {
        setEditConfig(false);
        setTail(flight.tail === undefined ? "TBD" : flight.tail);
        setConfig(flight.config === undefined ? "" : flight.config);
        setFuel(flight.fuel === undefined ? "" : flight.fuel);
        setLoad(flight.load === undefined ? "" : flight.load);
    }, [flight])

    useEffect(() => {
        if (!editConfig) {
            onCancel();
        }
    }, [editConfig, flight, onCancel])

    return (

        <Paper className={classes.flightPaper}>
            <FabControls
                edit={editConfig}
                setEdit={setEditConfig}
                onCancel={onCancel}
                onCommit={onCommit}
                hidden={editHidden}
            />
            <Container className={classes.flightContainer}>
                <Grid container>
                    <Grid item xs={12}><b>{title ? title : "Aircraft"}</b></Grid>
                    <Grid item xs={12}>
                        <OptionSelector
                            value={tail === undefined ? "TBD" : tail}
                            selectType={SelectTail}
                            onChange={(value) => {setTail(value)}}
                            disabled={!editConfig}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label={"Config"}
                            value={config}
                            onChange={(event) => setConfig(event.target.value)}
                            disabled={!editConfig}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label={"Fuel"}
                            value={fuel}
                            onChange={(event) => {
                                const regEx = /^[0-9]*\.?[0-9]*$/;
                                if (regEx.test(event.target.value)) {
                                    setFuel(event.target.value);
                                } else if (event.target.value === "") {
                                    setFuel("");
                                }
                            }}
                            disabled={!editConfig}
                            InputProps={{endAdornment: <InputAdornment position="end">K</InputAdornment>}}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label={"Load"}
                            value={load}
                            onChange={(event) => {setLoad(event.target.value)}}
                            disabled={!editConfig}
                        />
                    </Grid>
                </Grid>
            </Container>
        </Paper>
    )
}

export default compose(
    withStyles(appStyles),
    withAuthorization(isMember),
)(FlightConfig)