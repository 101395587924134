import {
    Container,
    Paper,
    TextField,
    withStyles,
} from "@material-ui/core";
import {FabControls} from "../Forms";
import React from "react";
import {appStyles} from "../../styles";
import {withAuthorization} from "../Session";
import {compose} from "recompose";
import {isMember} from "../../constants";

const FlightTextArea = (props) => {
    const {classes, label, edit, setEdit, value, setValue, onCancel, onSave, canEdit} = props;

    return (
        <Paper className={classes.flightPaper}>
            <FabControls
                edit={edit}
                setEdit={setEdit}
                onCancel={onCancel}
                onCommit={onSave}
                hidden={!canEdit}
            />
            <Container className={classes.flightContainer}>
                <TextField
                    label={label}
                    disabled={!edit}
                    multiline
                    fullWidth
                    minRows={10}
                    value={value}
                    onChange={(event) => {setValue(event.target.value)}}
                />
            </Container>
        </Paper>
    )
}

export default compose(
    withStyles(appStyles),
    withAuthorization(isMember)
)(FlightTextArea);