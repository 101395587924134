import React from "react";

import { withAuthentication } from '../Session';
import {Container, Card} from "@material-ui/core";

const NeedValidationPage = () => (
    <div>
        <Container>
            <p> </p>
            <Card>
                <h1 align={"center"}>Need Validation</h1>
                <p align={"center"}>You must have a squadron admin validate your membership.</p>
            </Card>
        </Container>
    </div>
);

export default withAuthentication(NeedValidationPage);