import React, {useState, useEffect} from "react";
import {
    Dialog,
    FormControlLabel,
    Button,
    Grid, Checkbox,
} from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";

import withAuthorization from "../Session/withAuthorization";
import {compose} from "recompose";
import {appStyles} from "../../styles";
import {DateSelector, OptionSelector, TimeSelector} from "../Forms";
import {isMember} from "../../constants";
import {addMinutes, subMinutes} from "date-fns";
import * as FLIGHT_OPTS from "./flightOptions";
import {CheckBox, CheckBoxOutlineBlank} from "@material-ui/icons";
import {DialogActions, DialogContent, DialogTitle} from "../Dialog";
import {adjustTime, utcDate, zonedStartOfDay} from "../Session/utils";
import {SelectSortie} from "../Forms/OptionSelector";
import {AuditableChange} from "../Audit";
import * as SORTIE_DATA from "./flightOptions";
import {firestoreAutoId} from "../Firebase";

const FlightEditor = (props) => {
    const {authUser, firebase, openDate} = props;
    const [autoShowTimes, setAutoShowTimes] = useState(true);
    const [eShowTime, setEShowTime] = useState(new Date());
    const [oShowTime, setOShowTime] = useState(new Date());
    const [crTime, setCRTime] = useState(new Date());
    const [takeoffTime, setTakeoffTime] = useState(new Date());
    const [landTime, setLandTime] = useState(new Date());
    const [sortieType, setSortieType] = useState("");
    const [eventList, setEventList] = useState([])

    useEffect(() => {
        if (openDate) {
            setSortieType(FLIGHT_OPTS.DefaultType);
            const mtvDayStart = zonedStartOfDay(openDate);
            const mtvTakeoff = addMinutes(mtvDayStart, FLIGHT_OPTS.SortieDefaults[FLIGHT_OPTS.DefaultType].takeoffTime * 60);
            changeTakeoffTime(mtvTakeoff, FLIGHT_OPTS.DefaultType,true);
            setLandTime(addMinutes(mtvDayStart, FLIGHT_OPTS.SortieDefaults[FLIGHT_OPTS.DefaultType].landTime * 60));
        }
    }, [openDate])

    const saveFlight = () => {
        const events = {}
        eventList.forEach((event) => {
            events[`${firestoreAutoId()}`] = {
                type: event.type,
                location: "",
                blockStart: adjustTime(takeoffTime, takeoffTime),
                blockEnd: adjustTime(takeoffTime, landTime, true),
            }
        })
        const delta = {
            autoShowTimes: autoShowTimes,
            eShowTime: adjustTime(takeoffTime, eShowTime),
            oShowTime: adjustTime(takeoffTime, oShowTime),
            crTime: adjustTime(takeoffTime, crTime),
            takeoffTime: adjustTime(takeoffTime, takeoffTime),
            landTime: adjustTime(takeoffTime, landTime, true),
            sortieType: sortieType,
            events: events,
        }
        AuditableChange(authUser.uid, firebase.flights(), null, delta, "Created flight")
        exitModal()
    };

    const updateEventList = (type, add) => {
        if (add) {
            setEventList([
                ...eventList,
                {type: type}
            ])
        } else {
            setEventList(eventList.filter(a => a.type !== type))
        }
    }

    const updateSortieType = (type) => {
        setSortieType(type);
        const mtvDayStart = zonedStartOfDay(takeoffTime);
        const mtvTakeoff = addMinutes(mtvDayStart, FLIGHT_OPTS.SortieDefaults[type].takeoffTime * 60);
        changeTakeoffTime(mtvTakeoff, type,true);
        setLandTime(addMinutes(mtvDayStart, FLIGHT_OPTS.SortieDefaults[type].landTime * 60));
    };

    const updateDefaults = () => {
        if (!autoShowTimes) {
            changeTakeoffTime(takeoffTime, sortieType, true);
        }
        setAutoShowTimes(!autoShowTimes);
    }

    const changeTakeoffTime = (time, type, defaultTimes) => {
        if (defaultTimes) {
            setEShowTime(subMinutes(time, FLIGHT_OPTS.SortieDefaults[type].eShow * 60));
            setOShowTime(subMinutes(time, FLIGHT_OPTS.SortieDefaults[type].oShow * 60));
            setCRTime(subMinutes(time, FLIGHT_OPTS.SortieDefaults[type].cr * 60));
        }
        setTakeoffTime(time);
    }

    const exitModal = () => {
        props.toggle();
    }

    return (
        <>
            <Dialog open={!!openDate}>
                <DialogTitle  id="customized-dialog-title" onClose={exitModal}>
                    Add Flight
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <DateSelector
                                label={"Date"}
                                value={takeoffTime}
                                onChange={() => {/*TODO: this could be editable but would have to update all the times*/}}
                                disabled={true}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <OptionSelector value={sortieType} selectType={SelectSortie} onChange={updateSortieType} />
                        </Grid>
                        <Grid item xs={4}>
                            <TimeSelector label={"Takeoff Time"} value={takeoffTime}
                                          onChange={(value) => changeTakeoffTime(utcDate(value), sortieType, autoShowTimes)} />
                        </Grid>
                        <Grid item xs={4}>
                            <TimeSelector label={"Land Time"} value={landTime} onChange={(value) => setLandTime(utcDate(value))} />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        color={"primary"}
                                        checked={autoShowTimes}
                                        icon={<CheckBoxOutlineBlank fontSize="small"/>}
                                        checkedIcon={<CheckBox fontSize="small"/>}
                                        onClick={updateDefaults}
                                    />
                                }
                                label={"Use Default Preflight Times"}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TimeSelector label={"O Show Time"} value={oShowTime} onChange={(value) => setOShowTime(utcDate(value))}
                                          disabled={autoShowTimes} />
                        </Grid>
                        <Grid item xs={4}>
                            <TimeSelector label={"E Show Time"} value={eShowTime} onChange={(value) => setEShowTime(utcDate(value))}
                                          disabled={autoShowTimes} />
                        </Grid>
                        <Grid item xs={4}>
                            <TimeSelector label={"Crew Ready"} value={crTime} onChange={(value) => setCRTime(utcDate(value))}
                                          disabled={autoShowTimes} />
                        </Grid>
                        {Object.keys(SORTIE_DATA.SortieEvents).map((keyName, keyIndex) =>
                            {
                                const exists = eventList.some(v => v.type === keyName)
                                return(
                                <Grid item key={keyIndex} xs={4}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                key={keyIndex}
                                                color={"primary"}
                                                checked={exists}
                                                onClick={() => updateEventList(keyName, !exists) }
                                            />
                                        }
                                        label={SORTIE_DATA.SortieEvents[keyName].name} />
                                </Grid>
                                )
                            }
                        )}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        color="default"
                        data-dismiss="modal"
                        type="button"
                        onClick={exitModal}
                    >
                        Close
                    </Button>
                    <Button
                        color="primary"
                        type="button"
                        onClick={saveFlight}
                    >
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default compose(
    withStyles(appStyles),
    withAuthorization(isMember),
)(FlightEditor);
