import {FormControl, InputLabel, MenuItem, Select} from "@material-ui/core";
import * as SORTIE_DATA from "../Flights/flightOptions";
import React from "react";

const EventTypeSelector = (props) => {
    const {value, disabled, onChange} = props;
    return (
        <>
            <FormControl style={{minWidth: 100}} disabled={disabled}>
                <InputLabel>
                    Event Type
                </InputLabel>
                <Select
                    value={value}
                    onChange={(event) => onChange(event.target.value)}
                >
                    {Object.keys(SORTIE_DATA.SortieEvents).map((keyName, keyIndex) =>
                        <MenuItem value={keyName} key={keyIndex} >
                            {SORTIE_DATA.SortieEvents[keyName].name}
                        </MenuItem>
                    )}
                </Select>
            </FormControl>
        </>
    );
}

export default EventTypeSelector;