import React, {useState} from 'react';
import { Link as ReactLink, useLocation } from 'react-router-dom';

import {withAuthorization} from "../Session";
import {
    AppBar,
    Toolbar,
    Typography,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Divider,
    Drawer,
    Hidden,
    withStyles,
} from "@material-ui/core";
import {
    Menu,
} from "@material-ui/icons"

import {SignOutList} from "../SignOut";
import {appStyles} from "../../styles";
import {ROUTES} from "../../App";
import {compose} from "recompose";
import {isMember} from "../../constants";
import withSessionData from "../Session/withSessionData";


const Navigation = (props) => {
    const {classes, authUser, sessionData} = props;
    const location = useLocation().pathname.split('/')[1];
    const [drawerOpen, setDrawerOpen] = useState(false);

    const MAIN_LINKS = [
        ROUTES.HOME,
        ROUTES.TRIP_BOARD,
        ROUTES.SCHEDULE,
    ]

    const ACCOUNT_LINKS = [
        ROUTES.ACCOUNT,
        ROUTES.ADMIN,
    ]

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setDrawerOpen(open);
    };

    const list = (sections) => (
        <div
            role="presentation"
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
        >
            {sections.map((links) => {
                return(
                <>
                <List>
                    {
                        links.map((route) => {
                            const Icon = route.icon;
                            return(
                                <Hidden xsUp={authUser.role < route.role}>
                                    <ListItem button component={ReactLink} to={route.path}>
                                        <ListItemIcon><Icon /></ListItemIcon>
                                        <ListItemText primary={route.name} />
                                    </ListItem>
                                </Hidden>
                            );
                        })
                    }
                </List>
                <Divider />
                </>
                );
            })}
            <Divider />
            <List>
                <SignOutList/>
            </List>
        </div>
    );

    const NavDrawer = (props) => {
        return (
            <div>
                <React.Fragment key={"left"}>
                    <Drawer anchor={"left"} open={props.open} onClose={toggleDrawer(false)} >
                        {list([MAIN_LINKS, ACCOUNT_LINKS])}
                    </Drawer>
                </React.Fragment>
            </div>
        );
    }

    return (
        <Hidden xsUp={location.toLocaleUpperCase() === 'SCHEDULE' && sessionData.displayMode}>
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        className={classes.menuButton}
                        color="inherit"
                        aria-label="menu"
                        onClick={() => setDrawerOpen(true)}
                    >
                        <Menu/>
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                        {location !== "" ? location.toLocaleUpperCase() : "HOME"}
                    </Typography>
                </Toolbar>
            </AppBar>
            <div className={classes.offset} />
            <NavDrawer open={drawerOpen} />
        </Hidden>
    );
}

export default compose(
    withStyles(appStyles),
    withAuthorization(isMember),
    withSessionData,
)(Navigation);