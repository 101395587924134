import React, { Component } from 'react';
import { Link as ReactLink, withRouter } from 'react-router-dom';
import { Grid, TextField, Paper, Button, Link, Typography, withStyles } from '@material-ui/core';
import { compose } from 'recompose';

import {ROUTES} from '../../App';
import {ROLES} from '../../constants'
import { withFirebase } from "../Firebase";
import {appStyles} from "../../styles";

const SignUpPage = (props) => (
    <>
        <Typography className={props.classes.pageTitle} variant={"h4"}>SignUp</Typography>
        <SignUpForm />
    </>
);

const INITIAL_STATE = {
    username: '',
    email: '',
    passwordOne: '',
    passwordTwo: '',
    error: null,
};

class SignUpFormBase extends Component {
    constructor(props) {
        super(props);

        this.state = { ...INITIAL_STATE };
    }

    onSubmit = event => {
        const { username, email, passwordOne } = this.state;

        this.props.firebase
            .doCreateUserWithEmailAndPassword(email, passwordOne)
            .then(authUser => {
            // Create a user in your Firebase realtime database
            return this.props.firebase
                .user(authUser.user.uid)
                .set({
                    username: username,
                    email: email,
                    role: ROLES.UNVALIDATED,
                    crewPosition: "U",
                    qual: "U",
                });
            })
            .then(authUser => {
                this.setState({ ...INITIAL_STATE });
                this.props.history.push(ROUTES.HOME.path);
            })
            .catch(error => {
                this.setState({ error });
            });

        event.preventDefault();
    }

    onChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    render() {
        const {
            username,
            email,
            passwordOne,
            passwordTwo,
            error,
        } = this.state;
        const {classes} = this.props
        const isInvalid =
            passwordOne !== passwordTwo ||
            passwordOne === '' ||
            email === '' ||
            username === '';

        return (
            <Paper className={classes.paper} elevation={3}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <TextField
                            required
                            fullWidth
                            name="username"
                            value={username}
                            onChange={this.onChange}
                            label="Full Name"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            required
                            fullWidth
                            name="email"
                            value={email}
                            onChange={this.onChange}
                            label="Email Address"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            required
                            fullWidth
                            name="passwordOne"
                            value={passwordOne}
                            onChange={this.onChange}
                            type="password"
                            label="Password"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            required
                            fullWidth
                            name="passwordTwo"
                            value={passwordTwo}
                            onChange={this.onChange}
                            type="password"
                            label="Confirm Password"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            variant={"contained"}
                            color={"primary"}
                            disabled={isInvalid}
                            onClick={this.onSubmit}
                        >
                            Sign Up
                        </Button>
                    </Grid>
                    {error && <p>{error.message}</p>}
                </Grid>
            </Paper>
        );
    }
}

const SignUpLink = () => (
    <Typography>
        Don't have an account? <Link component={ReactLink} to={ROUTES.SIGN_UP.path}>Sign Up</Link>
    </Typography>
);

const SignUpForm = compose(
    withRouter,
    withFirebase,
    withStyles(appStyles),
)(SignUpFormBase);

export default withStyles(appStyles)(SignUpPage);

export { SignUpForm, SignUpLink };