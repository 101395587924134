import React, {useEffect, useState} from "react";
import {
    Dialog,
    FormControlLabel,
    Button,
    Grid, Checkbox, TextField,
} from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";

import withAuthorization from "../Session/withAuthorization";
import {compose} from "recompose";
import {appStyles} from "../../styles";
import {DateSelector, OptionSelector, TimeSelector} from "../Forms";
import {isMember} from "../../constants";
import {addDays, subMinutes, addMinutes} from "date-fns";
import {CheckBox, CheckBoxOutlineBlank} from "@material-ui/icons";
import {DialogTitle, DialogActions, DialogContent} from "../Dialog"
import Hidden from "@material-ui/core/Hidden";
import * as FLIGHT_OPTS from "../Flights/flightOptions";
import {DefaultTripLength, DefaultTripStart} from "../Flights/flightOptions";
import {adjustTime, utcDate, zonedStartOfDay} from "../Session/utils";
import {SelectSortie} from "../Forms/OptionSelector";
import {AuditableChange} from "../Audit";

const TripEditor = (props) => {
    const {authUser, firebase, isOpen} = props;
    const [departureDate, setDeparureDate] = useState(addDays(new Date(), DefaultTripStart));
    const [returnDate, setReturnDate] = useState(addDays(new Date(), DefaultTripStart + DefaultTripLength));
    const [addDeparture, setAddDeparture] = useState(false);
    const [departureTimes, setDepartureTimes] = useState({});
    const [addReturn, setAddReturn] = useState(false);
    const [returnTimes, setReturnTimes] = useState({});
    const [tripLocation, setTripLocation] = useState("")

    useEffect(() => {
        if (isOpen) {
            setDeparureDate(addDays(new Date(), DefaultTripStart));
            setReturnDate(addDays(new Date(), DefaultTripStart + DefaultTripLength));
            setDepartureTimes({
                takeoffTime: addMinutes(zonedStartOfDay(new Date()),
                    FLIGHT_OPTS.SortieDefaults["XC"].takeoffTime * 60),
                landTime: addMinutes(zonedStartOfDay(new Date()),
                FLIGHT_OPTS.SortieDefaults["XC"].landTime * 60),
            })
            setReturnTimes({
                takeoffTime: addMinutes(zonedStartOfDay(new Date()),
                    FLIGHT_OPTS.SortieDefaults["XC"].takeoffTime * 60),
                landTime: addMinutes(zonedStartOfDay(new Date()),
                    FLIGHT_OPTS.SortieDefaults["XC"].landTime * 60),
            })
        }
    }, [isOpen])

    const saveTrip = () => {
        const delta = {
            location: tripLocation,
            xcd: getFlight(departureDate, departureTimes),
            xcr: getFlight(returnDate, returnTimes),
            rons: {
                default: {
                    arrive: departureDate.toISOString(),
                    depart: returnDate.toISOString(),
                    location: tripLocation,
                }
            }
        }
        AuditableChange(authUser.uid, firebase.trips(), null, delta,
            "Created trip")
        exitModal()
    };

    const exitModal = () => {
        props.toggle();
    }

    const getFlight = (flightDate, flightTimes) => {
        let sortieType = "XC";
        return {
            autoShowTimes: true,
            eShowTime: subMinutes(flightTimes.takeoffTime, FLIGHT_OPTS.SortieDefaults[sortieType].eShow * 60).toISOString(),
            oShowTime: subMinutes(flightTimes.takeoffTime, FLIGHT_OPTS.SortieDefaults[sortieType].oShow * 60).toISOString(),
            crTime: subMinutes(flightTimes.takeoffTime, FLIGHT_OPTS.SortieDefaults[sortieType].cr * 60).toISOString(),
            takeoffTime: adjustTime(flightDate, flightTimes.takeoffTime),
            landTime: adjustTime(flightDate, flightTimes.landTime, true),
            sortieType: sortieType,
        }
    }

    const updateTimes = (set, takeoffTime, landTime) => {
        set({
            takeoffTime: takeoffTime,
            landTime: landTime,
        })
    }

    const isValid = tripLocation === "";

    return (
        <>
            <Dialog open={isOpen}>
                <DialogTitle  id="customized-dialog-title" onClose={exitModal}>
                    Add Trip
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <DateSelector
                                label={"Departure Date"}
                                value={departureDate}
                                onChange={(value) => {
                                    let date = utcDate(value);
                                    if (date > returnDate) {
                                        setReturnDate(addDays(date, DefaultTripLength));
                                    }
                                    setDeparureDate(date);
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <DateSelector
                                label={"Return Date"}
                                value={returnDate}
                                onChange={(value) => {setReturnDate(utcDate(value))}}
                                minDate={departureDate}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label={"Location"}
                                value={tripLocation}
                                onChange={(event) => setTripLocation(event.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        color={"primary"}
                                        checked={addDeparture}
                                        icon={<CheckBoxOutlineBlank fontSize="small"/>}
                                        checkedIcon={<CheckBox fontSize="small"/>}
                                        onClick={() => setAddDeparture(!addDeparture)}
                                    />
                                }
                                label={"Edit departure flight"}
                            />
                        </Grid>
                        <Hidden xsUp={!addDeparture}>
                            <Grid item xs={3}>
                                <OptionSelector value={"XC"} selectType={SelectSortie} disabled={true} />
                            </Grid>
                            <Grid item xs={4}>
                                <TimeSelector
                                    label={"Takeoff Time"} value={departureTimes.takeoffTime}
                                    onChange={(value) => updateTimes(setDepartureTimes, utcDate(value), departureTimes.landTime)} />
                            </Grid>
                            <Grid item xs={4}>
                                <TimeSelector
                                    label={"Land Time"} value={departureTimes.landTime}
                                    onChange={(value) => updateTimes(setDepartureTimes, departureTimes.takeoffTime, utcDate(value))} />
                            </Grid>
                        </Hidden>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        color={"primary"}
                                        checked={addReturn}
                                        icon={<CheckBoxOutlineBlank fontSize="small"/>}
                                        checkedIcon={<CheckBox fontSize="small"/>}
                                        onClick={() => setAddReturn(!addReturn)}
                                    />
                                }
                                label={"Edit return flight"}
                            />
                        </Grid>
                        <Hidden xsUp={!addReturn}>
                            <Grid item xs={3}>
                                <OptionSelector value={"XC"} selectType={SelectSortie} disabled={true} />
                            </Grid>
                            <Grid item xs={4}>
                                <TimeSelector
                                    label={"Takeoff Time"} value={returnTimes.takeoffTime}
                                    onChange={(value) => updateTimes(setReturnTimes, utcDate(value), returnTimes.landTime)} />
                            </Grid>
                            <Grid item xs={4}>
                                <TimeSelector
                                    label={"Land Time"} value={returnTimes.landTime}
                                    onChange={(value) => updateTimes(setReturnTimes, returnTimes.takeoffTime, utcDate(value))} />
                            </Grid>
                        </Hidden>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        color="default"
                        data-dismiss="modal"
                        type="button"
                        onClick={exitModal}
                    >
                        Close
                    </Button>
                    <Button
                        color="primary"
                        type="button"
                        onClick={saveTrip}
                        disabled={isValid}
                    >
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default compose(
    withStyles(appStyles),
    withAuthorization(isMember),
)(TripEditor);
