import React from 'react';
import {
  Route,
  BrowserRouter as Router
} from 'react-router-dom';
import HomePage from './components/Home';
import Navigation from "./components/Navigation";
import SchedulePage from './components/Schedule';
import SignUpPage from './components/SignUp';
import SignInPage from './components/SignIn';
import PasswordForgetPage from './components/PasswordForget';
import AccountPage from './components/Account';
import AdminPage from './components/Admin';
import NeedValidationPage from "./components/NeedValidation";
import { withAuthentication } from "./components/Session";
import FlightPage from "./components/Flights";
import {CssBaseline, ThemeProvider, withStyles} from "@material-ui/core";
import {Home, Event, LocationCity, Person, Settings} from "@material-ui/icons";
import {appTheme, appStyles} from "./styles";
import {ROLES} from "./constants";
import {compose} from "recompose";
import {providesSessionData} from "./components/Session/withSessionData";
import TripBoard from "./components/TripBoard";
import TripPage from "./components/Trips";

export const ROUTES = {
    HOME: {
        path: "/home",
        name: "Home",
        icon: Home,
        component: HomePage,
    },
    TRIP_BOARD: {
        path: "/trips",
        name: "Trip Board",
        icon: LocationCity,
        component: TripBoard,
    },
    SCHEDULE: {
        name: "Schedule",
        path: "/schedule",
        icon: Event,
        component: SchedulePage,
        noFrame: true,
    },
    ACCOUNT: {
        name: "Account",
        path: "/account",
        icon: Person,
        component: AccountPage,
    },
    ADMIN: {
        name: "Admin",
        path: "/admin",
        icon: Settings,
        component: AdminPage,
        role: ROLES.ADMIN,
    },
    SIGN_IN: {
        path: "/signin",
        component: SignInPage
    },
    SIGN_UP: {
        path: "/signup",
        component: SignUpPage
    },
    PW_FORGET: {
        path: "/pw-forget",
        component: PasswordForgetPage
    },
    NEED_VALIDATION: {
        path: "/need-validation",
        component: NeedValidationPage
    },
    FLIGHT: {
        path: "/flight/:flightId",
        component: FlightPage
    },
    TRIP: {
        path: "/trip/:tripId",
        component: TripPage,
    }
}

const App = (props) => {
    const {classes} = props;
    return (
        <ThemeProvider theme={appTheme}>
            <CssBaseline />
            <Router>
                <Navigation />
                {Object.values(ROUTES).map((route, key) => {
                    return(
                        <>
                        {route.noFrame ?
                            <Route key={key} path={route.path} component={route.component}/>
                        :
                            <main className={classes.layout}>
                                <Route key={key} path={route.path} component={route.component}/>
                            </main>
                        }
                        </>
                    );
                })}
                <main className={classes.layout}>
                    <Route exact path={"/"} component={HomePage}/>
                </main>
            </Router>
            </ThemeProvider>
    );
};

export default compose(
    withAuthentication,
    withStyles(appStyles),
    providesSessionData,
)(App);
