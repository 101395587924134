import {appStyles} from "../../styles";
import {MuiPickersUtilsProvider, TimePicker} from "@material-ui/pickers";
import {InputAdornment, withStyles} from "@material-ui/core";
import {Event} from "@material-ui/icons";
import React from "react";
import DateFnsUtils from "@date-io/date-fns";
import {utcDate, zonedDate} from "../Session/utils";

const TimeSelector = (props) => {
    const {classes, label, value, onChange, disabled} = props;
    return (
        <>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <TimePicker
                    className={classes.timePicker}
                    label={label}
                    value={zonedDate(value)}
                    format={"HHmm"}
                    ampm={false}
                    onChange={(value) => onChange(utcDate(value).toISOString())}
                    disabled={disabled}
                    minutesStep={5}
                    InputProps={{
                        endAdornment: <InputAdornment position={"end"}><Event/></InputAdornment>
                    }}
                />
            </MuiPickersUtilsProvider>
        </>
    )
}

export default withStyles(appStyles)(TimeSelector);