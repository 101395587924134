import {appStyles} from "../../styles";
import {IconButton, Grid, Hidden, withStyles} from "@material-ui/core";
import {Cancel, Delete, Edit} from "@material-ui/icons";
import React from "react";


const HoverControls = withStyles(appStyles)((props) => {
    const {classes, onCancel, onEdit, onDelete, blocked, content} = props;
    return (
        <>
            <Grid container>
            <Grid item className={blocked ? "no-hide" : "will-hide"}>
                {content}
            </Grid>
            <Hidden xsUp={blocked}>
                <Grid item className={`hidden-control`}>
                    <Hidden xsUp={!onCancel}>
                        <IconButton onClick={onCancel} className={classes.noPadding}>
                            <Cancel/>
                        </IconButton>
                    </Hidden>
                    <Hidden xsUp={!onEdit}>
                        <IconButton onClick={onEdit} className={classes.noPadding}>
                            <Edit/>
                        </IconButton>
                    </Hidden>
                    <Hidden xsUp={!onDelete}>
                        <IconButton onClick={onDelete} className={classes.noPadding}>
                            <Delete/>
                        </IconButton>
                    </Hidden>
                </Grid>
            </Hidden>
            </Grid>
        </>
    )
})

export default HoverControls;
