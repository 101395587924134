/*
 * Defines default authorization conditions
 */
export const isUser = authUser => !!authUser
export const isMember = authUser => authUser && authUser.role >= ROLES.MEMBER;
export const isScheduler = authUser => authUser && authUser.role >= ROLES.SCHEDULER;
export const isAdmin = authUser => authUser && authUser.role === ROLES.ADMIN;

export const showTime = (authUser, flight) =>
    CREW_POS[authUser.crewPosition] === CREW_POS.L ? flight.eShowTime : flight.oShowTime

/*
 * Defines the access level of a user.
 */
export const ROLES = {
    UNVALIDATED: 0,
    MEMBER: 1,
    SCHEDULER: 2,
    ADMIN: 3,
}

/*
 * Defines the identifier and string for each crew position.
 */
export const CREW_POS = {
    U: "NC",
    P: "P",
    N: "CSO",
    L: "LM",
}

/*
 * Defines the qualification levels of a crew member.
 */
export const CREW_QUAL = {
    U: "U",
    M: "M",
    I: "I",
    E: "E",
}

/*
 * Defines the amount of time before the takeoff time that each type of
 * user becomes locked out from making changes.
 */
export const SORTIE_LOCKS = {
    USER: 48,
    SCHED: 24,
    ADMIN: 0,
}

/*
 * Defines the day of the week for the calendar views firstDay field.
 */
export const WEEK_DAYS = {
    SUNDAY: 0,
    MONDAY: 1,
    TUESDAY: 2,
    WEDNESDAY: 3,
    THURSDAY: 4,
    FRIDAY: 5,
    SATURDAY: 6,
}

export const EVENT_STATUS = {
    UNKNOWN: "No Status",
    COMPLETED: "Complete",
    USER_CNX: "User Cnx",
    MX_CNX: "Mx Cnx",
    WX_CNX: "Wx Cnx",
    OPS_CNX: "Ops Cnx",
}
