import React from 'react';

import { withFirebase } from '../Firebase';
import {Button} from "@material-ui/core";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ExitToApp from "@material-ui/icons/ExitToApp";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";

const SignOutButton = ({ firebase }) => (
    <Button type="button" onClick={firebase.doSignOut}>
        Sign Out
    </Button>
);

const SignOut = ({firebase}) => (
    <ListItem button onClick={firebase.doSignOut}>
        <ListItemIcon><ExitToApp /></ListItemIcon>
        <ListItemText primary={"Sign Out"} />
    </ListItem>
)

export const SignOutList = withFirebase(SignOut);
export default withFirebase(SignOutButton);