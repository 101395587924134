import {FormControl, InputLabel, MenuItem, Select} from "@material-ui/core";
import * as SORTIE_DATA from "../Flights/flightOptions";
import React from "react";

export const SelectSortie = "sortie";
export const SelectTail = "tail";

const OptionSelector = (props) => {
    const {value, selectType, disabled, onChange} = props;

    let label = "label";
    let map = {"default" : {index: 0, name: "default"}}

    switch (selectType) {
        case SelectSortie:
            label = "Flight Type";
            map = SORTIE_DATA.SortieDefaults;
            break;
        case SelectTail:
            label = "Tail Number";
            map = SORTIE_DATA.TailNumbers;
            break;
        default:
    }

    return (
        <>
            <FormControl style={{minWidth: 100}} disabled={disabled}>
                <InputLabel>
                    {label}
                </InputLabel>
                <Select
                    value={value}
                    onChange={(event) => onChange(event.target.value)}
                >
                    {Object.keys(map).map((keyName, keyIndex) =>
                        <MenuItem value={keyName} key={keyIndex} >
                            {map[keyName].name}
                        </MenuItem>
                    )}
                </Select>
            </FormControl>
        </>
    );
}

export default OptionSelector;