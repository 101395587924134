import {
    Button,
    Container,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Fab, Grid,
    Hidden,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    withStyles,
} from "@material-ui/core";
import {isMember, ROLES} from "../../constants";
import React, {useState} from "react";
import {compose} from "recompose";
import {appStyles} from "../../styles";
import {withAuthorization} from "../Session";
import {Add, Check, Clear} from "@material-ui/icons";
import {ConfirmDialog, DateSelector, HoverControls} from "../Forms";
import fb from "firebase";
import {firestoreAutoId} from "../Firebase";
import {viewDate} from "../Session/utils";
import {AuditableChange} from "../Audit";

const Rons = (props) => {
    const {authUser, classes, schedLock, adminLock, rons, tripId, departureDate, returnDate} = props;

    const [addRon, setAddRon] = useState(false);
    const [ronEditing, setRonEditing] = useState(false);

    const canEdit = !adminLock && (!schedLock || authUser.role === ROLES.ADMIN) && authUser.role > ROLES.MEMBER;

    return (
        <>
            <Paper className={classes.flightPaper}>
                <Hidden xsUp={!canEdit}>
                    <Fab className={classes.fab}
                         color="default"
                         aria-label="add"
                         size={"small"}
                         onClick={() => setAddRon(true)}
                    >
                        <Add />
                    </Fab>
                </Hidden>
                <Hidden xsUp={canEdit}>
                    <Button disabled={true}/>
                </Hidden>
                <AddRon
                    isOpen={addRon}
                    tripId={tripId}
                    departureDate={departureDate}
                    returnDate={returnDate}
                    onClose={() => setAddRon(false)}
                    />
                <TableContainer component={Container} className={classes.flightContainer}>
                    <b>RONs</b>
                    <Table className={classes.table} size={"small"}>
                        <TableHead>
                            <Grid container component={TableRow}>
                                <Grid item component={TableCell} xs={6}>Location</Grid>
                                <Grid item component={TableCell} xs={3} align={"right"}>Arrive</Grid>
                                <Grid item component={TableCell} xs={3} align={"right"}>Depart</Grid>
                            </Grid>
                        </TableHead>
                        <TableBody>
                            {rons ?
                                Object.entries(rons).sort((a, b) => {
                                    let first = new Date(a[0].arrive);
                                    let second = new Date(b[0].arrive);
                                    if (first === second) return 0;
                                    return first < second ? 1 : -1;
                                }).map(([id, ron]) => {
                                    return(
                                        <>
                                            <RonRow
                                                tripId={tripId}
                                                ronId={id}
                                                ron={ron}
                                                departureDate={departureDate}
                                                returnDate={returnDate}
                                                canEdit={!ronEditing && canEdit}
                                                lockEditing={setRonEditing}
                                            />
                                        </>
                                        );
                                    })
                                :
                                <>
                                    <Grid container component={TableRow}>
                                        <Grid item component={TableCell} xs={12}>None Scheduled</Grid>
                                    </Grid>
                                </>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </>
    )
}

const RonRow = compose(withAuthorization(isMember),withStyles(appStyles))((props) => {
    const {authUser, classes, tripId, ron, ronId, canEdit, lockEditing, firebase, departureDate, returnDate} = props;

    const [confirmDelete, setConfirmDelete] = useState(false);
    const [editing, setEditing] = useState(false);
    const [location, setLocation] = useState(ron.location);
    const [arriveDate, setArriveDate] = useState(new Date(ron.arrive));
    const [departDate, setDepartDate] = useState(new Date(ron.depart));

    const startEditing = () => {
        lockEditing(true);
        setLocation(ron.location);
        setArriveDate(new Date(ron.arrive));
        setDepartDate(new Date(ron.depart));
        setEditing(true);
    }

    const stopEditing = () => {
        setEditing(false);
        lockEditing(false);
    }

    const deleteEvent = () => {
        setConfirmDelete(false);
        const delta = {
            rons: {
                [`${ronId}`]: fb.firestore.FieldValue.delete()
            }}
        AuditableChange(authUser.uid, firebase.trip(tripId), {rons: {}}, delta,
            `Removed RON`)
    }

    const saveEvent = () => {
        stopEditing();
        const delta = {
            rons: {
                [`${ronId}`]: {
                    location: location,
                    arrive: arriveDate.toISOString(),
                    depart: departDate.toISOString(),
                }
            }}
        AuditableChange(authUser.uid, firebase.trip(tripId), {rons: {}}, delta,
            `Edited RON to ${location}`)
    }

    return (
        <>
            <Grid container component={TableRow} className={classes.hoverRow}>
                <Hidden xsUp={editing}>
                    <Grid item component={TableCell} xs={6} align={"left"}>
                        {ron.location === "" ? "TBD" : ron.location}
                    </Grid>
                    <Grid item component={TableCell} xs={5} align={"right"}><>
                        {viewDate(new Date(ron.arrive), "dd MMM yy")}
                    </>
                    </Grid>
                    <Grid item component={TableCell} xs={1} className={classes.noPadding}>
                        <HoverControls
                            content={viewDate(new Date(ron.depart), "dd MMM yy")}
                            blocked={!canEdit}
                            onEdit={startEditing}
                            onDelete={() => setConfirmDelete(true)}
                        />
                    </Grid>
                </Hidden>
                <Hidden xsUp={!editing}>
                    <TableCell colSpan={3}>
                        <Grid container>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    label={"location"}
                                    value={location}
                                    onChange={(event) => setLocation(event.target.value)}
                                />
                            </Grid>
                            <Grid item xs={6} sm={4}>
                                <DateSelector
                                    label={"Arrive"}
                                    value={arriveDate}
                                    onChange={(value) => {setArriveDate(new Date(value))}}
                                    minDate={departureDate}
                                    maxDate={returnDate}
                                />
                            </Grid>
                            <Grid item xs={6} sm={4}>
                                <DateSelector
                                    label={"Depart"}
                                    value={departDate}
                                    onChange={(value) => {setDepartDate(new Date(value))}}
                                    minDate={departureDate}
                                    maxDate={returnDate}
                                />
                            </Grid>
                            <Grid item xs={12} >
                                <IconButton className={classes.alignRight} onClick={saveEvent} color={"primary"}>
                                    <Check />
                                </IconButton>
                                <IconButton className={classes.alignRight} onClick={stopEditing} color={"secondary"}>
                                    <Clear />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </TableCell>
                </Hidden>
            </Grid>
            <ConfirmDialog
                open={confirmDelete}
                title={"Confirm"}
                text={`Are you sure you want to remove ${ron.location} from this trip?`}
                onCancel={() => setConfirmDelete(false)}
                onConfirm={deleteEvent}
            />
        </>
    );
});

const AddRon = withAuthorization(isMember)((props) => {
    const {authUser, tripId, departureDate, returnDate, onClose, isOpen, firebase} = props;
    const [ronLocation, setRonLocation] = useState("");
    const [arrive, setArrive] = useState(new Date(departureDate));
    const [depart, setDepart] = useState(new Date(returnDate));

    const saveEvent = () => {
        const delta = {
            rons: {
                [`${firestoreAutoId()}`]: {
                    location: ronLocation,
                    arrive: arrive.toISOString(),
                    depart: depart.toISOString(),
                }
            }
        }
        AuditableChange(authUser.uid, firebase.trip(tripId), {rons: {}}, delta,
            `Added RON to ${ronLocation}`)
        onClose();
    };

    return (
        <>
            <Dialog open={isOpen}>
                <DialogTitle onClose={onClose}>
                    Add RON
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <TextField
                                label={"Location"}
                                value={ronLocation}
                                onChange={(event) => setRonLocation(event.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <DateSelector label={"Arrive"} value={arrive} onChange={setArrive}
                                          minDate={departureDate}
                                          maxDate={depart} />
                        </Grid>
                        <Grid item xs={12}>
                            <DateSelector label={"Depart"} value={depart} onChange={setDepart}
                                          minDate={arrive}
                                          maxDate={returnDate} />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        color="default"
                        data-dismiss="modal"
                        type="button"
                        onClick={onClose}
                    >
                        Close
                    </Button>
                    <Button
                        color="primary"
                        type="button"
                        onClick={saveEvent}
                    >
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
})

export default compose(
    withStyles(appStyles),
    withAuthorization(isMember),
)(Rons);