import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/analytics';
import 'firebase/firestore';

const stagingConfig = {
    apiKey: "AIzaSyB1qw_EwgTFhpWg62q_bX_Z8Y6eA8DhhfQ",
    authDomain: "flight-schedule-staging.firebaseapp.com",
    databaseURL: "https://flight-schedule-staging-default-rtdb.firebaseio.com",
    projectId: "flight-schedule-staging",
    storageBucket: "flight-schedule-staging.appspot.com",
    messagingSenderId: "454898183155",
    appId: "1:454898183155:web:b1eddee27d15e963974086",
    measurementId: "G-FNH84PBV2T"
}

const prodConfig = {
    apiKey: "AIzaSyCF07HpcM3n9IaFxf9556SGzGMDNH9khGI",
    authDomain: "flight-schedule-237ed.firebaseapp.com",
    databaseURL: "https://flight-schedule-237ed.firebaseio.com",
    projectId: "flight-schedule-237ed",
    storageBucket: "flight-schedule-237ed.appspot.com",
    messagingSenderId: "151878902222",
    appId: "1:151878902222:web:30d53aa108bd7665cc4b9f",
    measurementId: "G-RYTXLH81JV"
};

class Firebase {
    constructor() {
        let config = {};
        switch (process.env.REACT_APP_DEPLOY_ENV) {
            case "staging":
                config = stagingConfig;
                break;
            case "dev":
            case "prod":
                config = prodConfig;
                break;
            default:
                // deployment environment must be specified
                return;
        }
        app.initializeApp(config);

        this.auth = app.auth();
        this.fs = app.firestore();
        if (process.env.REACT_APP_DEPLOY_ENV === "dev") {
            this.auth.useEmulator("http://localhost:9099/");
            this.fs.useEmulator("localhost", 8080);
        } else {
            this.analytics = app.analytics();
        }
    }

    // *** Firebase Auth API ***

    doCreateUserWithEmailAndPassword = (email, password) =>
        this.auth.createUserWithEmailAndPassword(email, password);

    doSignInWithEmailAndPassword = (email, password) =>
        this.auth.signInWithEmailAndPassword(email, password);

    doSignOut = () => this.auth.signOut();

    doPasswordReset = email => this.auth.sendPasswordResetEmail(email);

    doPasswordUpdate = password =>
        this.auth.currentUser.updatePassword(password);

    // *** User API ***

    user = uid => this.fs.collection('users').doc(uid);

    users = () => this.fs.collection('users');

    // *** Flight API ***

    flight = uid => this.fs.collection('flights').doc(uid);

    flights = () => this.fs.collection('flights');

    // *** Trip API ***

    trip = uid => this.fs.collection('trips').doc(uid);

    trips = () => this.fs.collection('trips');

}

export default Firebase;